import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import { LoadingType, ROLE, TALENT_SKILLS_TYPE, THEME } from "../../../../enums";
import {
    IAddContactStaff,
    // IAddContactStaff, 
    IAddSkill, IContact
} from "../../../../interfaces";

import {
    // addContactAccountManagers,
    // addContactRecruiters,
    selectCertificationsList,
    selectCertificationsListState,
    selectCredentialsList,
    selectCredentialsListState,
    selectEducationList,
    selectEducationListState,
    selectRequiredEquipmentList,
    selectRequiredEquipmentListState,
    selectSkillSetList,
    selectSkillSetListState,
    selectRoleUsersListState,
    selectAccountManagerRoleUsersList,
    selectRecruiterRoleUsersList,
    useAppDispatch, useAppSelector, postSnackbarMessage,
    selectManageContactUpdateState,
    addContactSkills,
} from "../../../../redux/store";

import { companyDetailsSliceActions } from "../../../../redux/companies/details/dashboard/company_details/company_details_reducer";
import { CustomButton, getStatusFromICreateState, getUserNameFromProfileDropdown, SpinnerScreen } from "../../../../utils";
import './create_option.scss';
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { CompaniesIcon, ContactsIcon } from "../../../../icons";
import { manageContactActions } from "../../../../redux/contacts/universal_directory/manage_contact/manage_contact_reducer";
import { addContactAccountManagers, addContactRecruiters, selectContactDetailsByIdState, updateContact } from "../../../../redux/contacts";
import React from "react";
interface Props {
    visible: boolean,
    data: {
        contact_id: string,
        contact?: IContact,
    },
    type: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

export const CreateContactOptionPopup = (props: Props) => {
    const {
        visible,
        data,
        type,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const skillSetListState = useAppSelector((state) => selectSkillSetListState(state));
    const credentialsListState = useAppSelector((state) => selectCredentialsListState(state));
    const certificationsListState = useAppSelector((state) => selectCertificationsListState(state));
    const educationListState = useAppSelector((state) => selectEducationListState(state));
    const requiredEquipmentListState = useAppSelector((state) => selectRequiredEquipmentListState(state));
    const roleUsersListState = useAppSelector((state) => selectRoleUsersListState(state));
    const contactUpdateState = useAppSelector((state) => selectManageContactUpdateState(state));

    const skillSetList = useAppSelector((state) => selectSkillSetList(state));
    const credentialsList = useAppSelector((state) => selectCredentialsList(state));
    const certificationsList = useAppSelector((state) => selectCertificationsList(state));
    const educationList = useAppSelector((state) => selectEducationList(state));
    const requiredEquipmentList = useAppSelector((state) => selectRequiredEquipmentList(state));
    const accountManagersList = useAppSelector((state) => selectAccountManagerRoleUsersList(state));
    const recruitersList = useAppSelector((state) => selectRecruiterRoleUsersList(state));

    /// Create optin states
    const contactState = useAppSelector((state) => selectContactDetailsByIdState(data.contact_id)(state));
    const skillSetAddState = contactState.professional_skills.add;
    const credentialsAddState = contactState.credentials.add;
    const certificationsAddState = contactState.certifications.add;
    const educationAddState = contactState.education.add;
    const requiredEquipmentAddState = contactState.safety_equipment.add;
    const accountManagerAddState = contactState.account_managers.add;
    const recruitersAddState = contactState.recruiters.add;

    const [selected, setSelected] = useState<string[]>([]);
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        if (skillSetAddState.loading === LoadingType.succeeded ||
            credentialsAddState.loading === LoadingType.succeeded ||
            certificationsAddState.loading === LoadingType.succeeded ||
            educationAddState.loading === LoadingType.succeeded ||
            requiredEquipmentAddState.loading === LoadingType.succeeded ||
            contactUpdateState.loading === LoadingType.succeeded ||
            accountManagerAddState.loading === LoadingType.succeeded ||
            recruitersAddState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(skillSetAddState.response ||
                credentialsAddState.response ||
                certificationsAddState.response ||
                educationAddState.response ||
                requiredEquipmentAddState.response ||
                accountManagerAddState.response ||
                recruitersAddState.response ||
                getStatusFromICreateState(contactUpdateState.response)));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [
        skillSetAddState.loading,
        credentialsAddState.loading,
        certificationsAddState.loading,
        educationAddState.loading,
        requiredEquipmentAddState.loading,
        contactUpdateState.loading,
        accountManagerAddState.loading,
        recruitersAddState.loading,
    ])

    function isFetchLoading() {
        return skillSetListState.loading === LoadingType.pending ||
            credentialsListState.loading === LoadingType.pending ||
            certificationsListState.loading === LoadingType.pending ||
            educationListState.loading === LoadingType.pending ||
            requiredEquipmentListState.loading === LoadingType.pending ||
            roleUsersListState.loading === LoadingType.pending ||
            contactState.loading === LoadingType.pending;

    }

    function isAddLoading() {
        return skillSetAddState.loading === LoadingType.pending ||
            credentialsAddState.loading === LoadingType.pending ||
            certificationsAddState.loading === LoadingType.pending ||
            educationAddState.loading === LoadingType.pending ||
            requiredEquipmentAddState.loading === LoadingType.pending ||
            contactUpdateState.loading === LoadingType.pending ||
            accountManagerAddState.loading === LoadingType.pending ||
            recruitersAddState.loading === LoadingType.pending;
    }

    function getError() {
        return skillSetAddState.error ||
            credentialsAddState.error ||
            certificationsAddState.error ||
            educationAddState.error ||
            requiredEquipmentAddState.error ||
            contactUpdateState.error ||
            accountManagerAddState.error ||
            recruitersAddState.error;
    }

    function getListOptions(): Array<{ label: string, value: string }> {
        switch (type) {
            case TALENT_SKILLS_TYPE.professional_skills: return skillSetList.map((doc, index) => ({ label: doc.skill_set, value: doc.id, isDisabled: contactState?.professional_skills?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.credentials: return credentialsList.map((doc, index) => ({ label: doc.credential, value: doc.id, isDisabled: contactState?.credentials?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.certifications: return certificationsList.map((doc, index) => ({ label: doc.certification, value: doc.id, isDisabled: contactState?.certifications?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.education: return educationList.map((doc, index) => ({ label: doc.education_requirement, value: doc.id, isDisabled: contactState?.education?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.safety_equipment: return requiredEquipmentList.map((doc, index) => ({ label: doc.required_equipment, value: doc.id, isDisabled: contactState?.safety_equipment?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.account_managers: return accountManagersList.map((doc, index) => ({ label: getUserNameFromProfileDropdown(doc), value: doc.id, isDisabled: contactState?.account_managers?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.recruiters: return recruitersList.map((doc, index) => ({ label: getUserNameFromProfileDropdown(doc), value: doc.id, isDisabled: contactState?.recruiters?.data!.map(doc => doc.id).includes(doc.id) }));
            default: return [];
        }
    }

    function getOptionForm() {
        return (
            <div className="cmpny-op-f-container">
                <div className="cmpny-op-f-header">
                    <FormDialogTilteHeader
                        title={`${t('add_option', { value: t(type) })}`}
                        titleIcon={<ContactsIcon width={"100%"} height={"100%"} />}
                    />
                </div>
                <div className="cmpny-op-f-content">
                    <CustomMultiFormSelect
                        name={type}
                        list={getListOptions()}
                        onChange={(value) => setSelected(value)}
                        required={true}
                        placeholder={t('select_option', { value: t(type) })}
                        value={selected}
                        customStyle={{ width: '-webkit-fill-available' }}
                    />
                    {getError() != null && <div className={"error-section"}>
                        <ApiError message={getError()?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="cmpny-op-f-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('cancel')}
                            enable={true}
                            backgroundColor={THEME.secondaryColor4}
                            onClick={() => {
                                if (getError()) handleClearError();
                                closePopup(onClose)
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={isAddLoading()}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('add')}
                            enable={selected.length != 0}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleAddOption}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={430}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                {isFetchLoading() ? <div className="loading-spinner"><SpinnerScreen /></div> : getOptionForm()}
            </DialogWrapper>

        </ActionDialogHolder>

    );

    // function 

    function handleClearError() {
        dispatch(companyDetailsSliceActions.clearCompanyDetailsAddOptionError({ id: data.contact_id, type: type }));
        dispatch(manageContactActions.clearManageContactStateError(["photo", "resume"]));
    }

    function handleAddOption() {
        switch (type) {
            case TALENT_SKILLS_TYPE.account_managers: {

                const payload: IAddContactStaff = {
                    contact_id: data.contact_id,
                    user_id_list: [...selected, ...(contactState?.data?.account_managers ?? []),],
                    role: ROLE.accountManager,
                }
                dispatch(addContactAccountManagers(payload));
                // if(data.contact){
                //     const payload: IContact = {
                //         ...data.contact,
                //         account_managers: [...data.contact.account_managers, ...selected],
    
                //     };
                //     dispatch(updateContact(payload));
                // }
                
                break;
            }
            case TALENT_SKILLS_TYPE.recruiters: {
                const payload: IAddContactStaff = {
                    contact_id: data.contact_id,
                    user_id_list: [...selected, ...(contactState?.data?.recruiters ?? []),],
                    role: ROLE.recruiter,
                }
                dispatch(addContactRecruiters(payload));
                // if(data.contact){
                //     const payload: IContact = {
                //         ...data.contact,
                //         recruiters: [...data.contact.recruiters, ...selected],
    
                //     };
                //     dispatch(updateContact(payload));
                // }
                
                break;
            }

            case TALENT_SKILLS_TYPE.professional_skills: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addContactSkills({ contact_id: data.contact_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.credentials: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addContactSkills({ contact_id: data.contact_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.certifications: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addContactSkills({ contact_id: data.contact_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.education: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addContactSkills({ contact_id: data.contact_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.safety_equipment: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addContactSkills({ contact_id: data.contact_id, value: payload }));
                break;
            }
            default: { break; }
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}