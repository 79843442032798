import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import '../paycheck_history/paycheck_history_section.scss'
import { useAppDispatch } from "../../../../../redux/store";
import { currencyConversion, SpinnerScreen } from "../../../../../utils";
import SearchBar from "../../../../../components/search_bar";
import { getDateString } from "../../../../../variables";
import { IPCRevHistory } from "../../../../../interfaces";

interface Props {
    checkRevHistList: IPCRevHistory | null;
    loading: boolean;
}

const PaycheckReverseHistorySection: React.FunctionComponent<Props> = (props) => {
    const {
        checkRevHistList,
        loading,
    } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const tableheaderList = [
        { title: "check_number", code: "check_number" },
        { title: "check_date", code: "check_date" },
        { title: "check_reversed_date", code: "check_reversed_date" },
        { title: "amount", code: "amount" },
        { title: "reversal_type", code: "reversal_type" },
    ];


    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getFlexNumber = (value: number) => {
        if (value === 0) return 1.5;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 2;
        if (value === 20) return 1;
        return 1;
    }

    const getFilteredList = () => {
        if (checkRevHistList?.records) {
            let list = checkRevHistList.records;

            if (checkRevHistList.records && sortedField != null) {
                list = checkRevHistList.records.sort((a, b) => {
                    const valueA =
                        a[sortedField] != null && a[sortedField] !== undefined
                            ? typeof a[sortedField] == typeof "1"
                                ? a[sortedField].trim().toLowerCase()
                                : a[sortedField]
                            : "";
                    const valueB =
                        b[sortedField] != null
                            ? typeof b[sortedField] == typeof "1"
                                ? b[sortedField].trim().toLowerCase()
                                : b[sortedField]
                            : "";
                    if (sortDirection === "asc") {
                        return valueA > valueB ? 1 : -1;
                    } else {
                        return valueA < valueB ? 1 : -1;
                    }
                });
            }

            return list?.filter(doc => {
                const str = search.trim().toLowerCase();
                return Object.values(doc).some(val =>
                    val && val.toString().trim().toLowerCase().includes(str)
                );
            });
        }

    }

    return (
        <div className="ep-pd-ph-container">
            <div className="ep-pd-ph-header">
                <div className="ep-pd-ph-header-left">
                    <div className="search-bar">
                        <SearchBar value={search} onChange={setSearch} onSearch={() => { }} placeholder="Search..." />
                    </div>
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <b>{getFilteredList()?.length}</b>
                        </span>
                    </div>

                </div>
                <div className="ep-pd-ph-header-right">
                    <div className="check-bal">
                        <span>
                            {`${t("check_reversal_amount")}: `}
                            <b>{checkRevHistList?.check_reversal_amount ? currencyConversion(checkRevHistList?.check_reversal_amount) : "$0.00"}</b>
                        </span>
                    </div>
                </div>
            </div>
            <div className="ep-pd-ph-table">
                <SortableTable
                    headerList={tableheaderList}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {loading ?
                        <SpinnerScreen />
                        :
                        <>
                            {getFilteredList() && getFilteredList()?.length! > 0 ? (
                                <>
                                    {getFilteredList()?.map((doc, index) => {
                                        return (
                                            <tr key={index}>
                                                <TableData customStyle={{ flex: getFlexNumber(0) }}><span>{doc.check_number}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{getDateString(doc.check_date)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{getDateString(doc.check_reversed_date)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{currencyConversion(doc.amount, 2)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc.reversal_type}</span></TableData>
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                                :
                                <>
                                    <tr>
                                        <TableData customStyle={{ flex: getFlexNumber(20), display: "flex", justifyContent: "center", alignContent: "center" }} align="center" >
                                            <div className="na_div">
                                                {t("no_pcr_history_available")}
                                            </div>
                                        </TableData>
                                    </tr>
                                </>
                            }

                        </>
                    }
                </SortableTable>
            </div>
        </div>
    );
}

export default PaycheckReverseHistorySection;