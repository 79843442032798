import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getMasterTaxSetup, selectMasterTaxSetupState, updateMasterTaxSetup } from "../../../../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { THEME, LoadingType } from "../../../../../../enums";
import { allowNumbers, CustomButton, FormInput, RegxPattern, SpinnerScreen } from "../../../../../../utils";
import { IMasterTaxCompanyBankSetup, IMasterTaxSetup } from "../../../../../../interfaces/master_tax";
import { useEffect, useState } from "react";
import { masterTaxInitialForm } from "../../master_tax_initialstate";
import ApiError from "../../../../../../components/api_error";
import { masterTaxSetupStateSliceActions } from "../../../../../../redux/admin_center/integration_partners/master_tax/master_tax_setup/master_tax_setup_reducer";
import "./disbursement_bank_setup.scss";
import { DateInput } from "../../../../../../components/date_input/date_input";
import CustomFormSelect from "../../../../../../components/form_selector/form_select";
import { getBankAccount, selectBankAccountList } from "../../../../../../redux/admin_center/back_office";

interface Props extends RouteComponentProps<any> { }

const DisbursementBankSetupPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const masterTaxState = useAppSelector((state) => selectMasterTaxSetupState(state));
    const bankAccountList = useAppSelector((state) => selectBankAccountList(state));

    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);
    const [payrollSelection, setPayrollSelection] = useState<string>("");
    const [wageSelection, setWageSelection] = useState<string>("");

    const getFormData = (value?: IMasterTaxCompanyBankSetup) => {
        if (value) {
            return value;
        }
        return {
            effective_date: 0,
            ach_bank_dest_point_number: "",
            payroll_tax_bank: {
                account_name: "",
                routing_number: "",
                account_number: "",
            },
            wage_attachment_bank: {
                account_name: "",
                routing_number: "",
                account_number: "",
            } || null,
        }
    }
    const [masterTaxFormState, setMasterTaxFormState] = useState<IMasterTaxSetup>(masterTaxInitialForm);
    const [formState, setFormState] = useState<IMasterTaxCompanyBankSetup>(masterTaxFormState.disbursement_bank);

    useEffect(() => {
        dispatch(masterTaxSetupStateSliceActions.clearState());
        dispatch(getBankAccount());
        dispatch(getMasterTaxSetup());
        return () => { }
    }, []);

    useEffect(() => {
        if (masterTaxState.loading === LoadingType.succeeded && masterTaxState.data) {
            setMasterTaxFormState(masterTaxState.data);
            const bankData = masterTaxState.data.disbursement_bank;
            if (bankData !== null) {
                setFormState(getFormData(bankData));
                setPayrollSelection(bankAccountList.find((bank) => bank.account_number === bankData.payroll_tax_bank.account_number)?.id ?? "");
                setWageSelection(bankAccountList.find((bank) => bankData.wage_attachment_bank && bank.account_number === bankData.wage_attachment_bank.account_number)?.id ?? "");
            }
        }
    }, [masterTaxState.loading, masterTaxState.data, bankAccountList]);


    useEffect(() => {
        if (masterTaxState.update.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage("Company Disbursement Bank setup completed successfully!" ?? null));
            dispatch(masterTaxSetupStateSliceActions.clearState());
            dispatch(getMasterTaxSetup());
        }
    }, [masterTaxState.update.loading]);

    const handleSave = () => {
        setCheckValidationOnSubmit(true);
        if (_isFormValid() && formState) {
            const isWageNull = formState.wage_attachment_bank && formState.wage_attachment_bank.account_number === "" ? true : false
            dispatch(updateMasterTaxSetup({
                ...masterTaxFormState,
                disbursement_bank: {
                    ...formState,
                    wage_attachment_bank: isWageNull ? null : formState.wage_attachment_bank
                },
            }));
            setCheckValidationOnSubmit(false);
        }
    };
    const handleCancel = () => {
        if (masterTaxState.data?.disbursement_bank) {
            setFormState(masterTaxState.data.disbursement_bank);
            setPayrollSelection(bankAccountList.find((bank) => bank.account_number === masterTaxState.data?.disbursement_bank.payroll_tax_bank.account_number)?.id ?? "");
            setWageSelection(bankAccountList.find((bank) => masterTaxState.data?.disbursement_bank.wage_attachment_bank && bank.account_number === masterTaxState.data?.disbursement_bank.wage_attachment_bank.account_number)?.id ?? "");
        }
        setCheckValidationOnSubmit(true)
    }
    const handleClearError = () => {
        dispatch(masterTaxSetupStateSliceActions.clearState());
    }
    const handleFieldChange = (id: string, val: string | null, parentId?: string) => {
        if (parentId) {
            setFormState({
                ...formState,
                [parentId]: {
                    ...formState[parentId],
                    [id]: val
                }
            })
        }
        else {
            setFormState({
                ...formState,
                [id]: val
            })
        }
    }
    const handleBankChange = (type: string, id: string) => {
        const bankData = bankAccountList.find((bank) => bank.id === id);

        if (type === "payroll") {
            let acntName = "";
            if (masterTaxFormState.disbursement_bank) {
                acntName = bankData?.account_number === masterTaxFormState.disbursement_bank.payroll_tax_bank.account_number ? masterTaxFormState.disbursement_bank.payroll_tax_bank.account_name : ""
            }
            setPayrollSelection(id);
            setFormState({
                ...formState,
                payroll_tax_bank: {
                    ...formState.payroll_tax_bank,
                    account_name: id === "new" ? "" : acntName,
                    account_number: bankData?.account_number || "",
                    routing_number: bankData?.routing_number || "",
                }
            });
        }
        else {
            setWageSelection(id);
            let acntName = "";
            if (masterTaxFormState.disbursement_bank && masterTaxFormState.disbursement_bank.wage_attachment_bank) {
                acntName = bankData?.account_number === masterTaxFormState.disbursement_bank.wage_attachment_bank.account_number ? masterTaxFormState.disbursement_bank.wage_attachment_bank.account_name : ""
            }
            setFormState({
                ...formState,
                wage_attachment_bank: {
                    ...formState.wage_attachment_bank,
                    account_name: id === "new" ? "" : acntName,
                    account_number: bankData?.account_number || "",
                    routing_number: bankData?.routing_number || "",
                }
            });
        }
    }
    // const isValidToFill = masterTaxState.data?.company_setup === null;
    const formValidators = (value: { text: string; field: string }) => {
        switch (value.field) {
            case "account_name":
            case "account_number": {
                if (value.text === "" || value.text === null) return t("validators.required");
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            case "routing_number": {
                if (value.text === "") return t("validators.required");
                if (value.text && value.text.length < 9) return t("validators.routinglimit");
                if (!value.text) return null;
                return null;
            }
            case "effective_date": {
                if (value.text === null || parseInt(value.text) === 0) return t("validators.required");
                return null;
            }
            case "wage_routing_number": {
                if (value.text && value.text.length < 9) return t("validators.routinglimit");
                if (!value.text) return null;
                return null;
            }
            default: {
                return null;
            }
        }
    }
    function _isFormValid() {
        const {
            effective_date, ach_bank_dest_point_number, payroll_tax_bank, wage_attachment_bank
        } = formState;

        if (
            // ach_bank_dest_point_number === "" ||
            payroll_tax_bank.account_name === "" ||
            payroll_tax_bank.routing_number === "" ||
            payroll_tax_bank.account_number === "" ||
            // wage_attachment_bank.account_name === "" ||
            // wage_attachment_bank.routing_number === "" ||
            // wage_attachment_bank.account_number === "" ||
            effective_date === 0 || effective_date === null
        ) {
            return false;
        }


        return true;
    }

    return (
        <div className="db-container">
            {/* <div className="db-header">
                {isValidToFill &&
                    <span className="note-span">
                        {t('note_finish_step1')}
                    </span>
                }
            </div> */}
            {masterTaxState.loading === LoadingType.pending ?
                <SpinnerScreen />
                :
                <div className="db-content">
                    <div className="db-input-row">
                        <div className="db-input-holder">
                            <DateInput
                                id={"effective_date"}
                                onChange={handleFieldChange}
                                placeholder={"mm/dd/yyyy"}
                                value={formState.effective_date}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                                label={t("effective_date")}
                                customStyle={{ width: "-webkit-fill-available" }}
                                required
                            />
                        </div>
                        <div className="db-input-holder ih-l">
                            <FormInput
                                id={"ach_bank_dest_point_number"}
                                onChange={handleFieldChange}
                                type={"text"}
                                value={formState.ach_bank_dest_point_number ?? ""}
                                label={t("ach_bank_dest_point_number")}
                                validations={formValidators}
                                placeholder=""
                                maxLength={9}
                                onKeyUp={allowNumbers}
                                onKeyDown={allowNumbers}
                                checkValidationOnSubmit={checkValidationOnSubmit}

                            />
                        </div>
                    </div>
                    <div className="col-div">
                        <div className="payroll-tb-row">
                            <div className="db-header-row">
                                <div className="title">
                                    <span className="db-title">
                                        {t('payroll_tax_bank')}
                                    </span>
                                </div>
                                <div className="selection-div">
                                    <CustomFormSelect
                                        list={
                                            [{ label: "Add New Bank", value: "new" }, ...bankAccountList.map((doc) => ({
                                                label: `${doc.bank_name} ${doc.purpose ? " - " + doc.purpose : ""}`,
                                                value: doc.id,
                                            }))]}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name=""
                                        value={payrollSelection}
                                        label={t("")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleBankChange('payroll', val)}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        validations={formValidators}

                                    />
                                </div>
                            </div>
                            <div className="db-input-row">
                                <div className="db-input-holder">
                                    <FormInput
                                        id={"account_name"}
                                        onChange={(id, val) => handleFieldChange(id, val, "payroll_tax_bank")}
                                        type={"text"}
                                        value={formState.payroll_tax_bank.account_name ?? ""}
                                        label={t("bank_account_name")}
                                        placeholder=""
                                        validations={formValidators}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        maxLength={23}
                                        required
                                    />
                                </div>
                                <div className="db-input-holder">
                                    <FormInput
                                        id={"routing_number"}
                                        onChange={(id, val) => handleFieldChange(id, val, "payroll_tax_bank")}
                                        type={"text"}
                                        value={formState.payroll_tax_bank.routing_number ?? ""}
                                        label={t("routing_number")}
                                        validations={formValidators}
                                        placeholder=""
                                        maxLength={9}
                                        onKeyUp={allowNumbers}
                                        onKeyDown={allowNumbers}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        disabled={(payrollSelection !== "new" && payrollSelection !== "" ? true : false)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="db-input-row">
                                <div className="db-input-holder">
                                    <FormInput
                                        id={"account_number"}
                                        onChange={(id, val) => handleFieldChange(id, val, "payroll_tax_bank")}
                                        type={"text"}
                                        value={formState.payroll_tax_bank.account_number ?? ""}
                                        label={t("account_number")}
                                        validations={formValidators}
                                        placeholder=""
                                        maxLength={17}
                                        onKeyUp={allowNumbers}
                                        onKeyDown={allowNumbers}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        disabled={(payrollSelection !== "new" && payrollSelection !== "" ? true : false)}
                                        required
                                    />
                                </div>

                            </div>

                        </div>
                        <div className="wage-tb-row">
                            <div className="db-header-row">
                                <div className="title">
                                    <span className="db-title">
                                        {t('wage_attachment_bank')}
                                    </span>
                                </div>
                                <div className="selection-div">
                                    <CustomFormSelect
                                        list={
                                            [{ label: "Add New Bank", value: "new" }, ...bankAccountList.map((doc) => ({
                                                label: `${doc.bank_name} ${doc.purpose ? " - " + doc.purpose : ""}`,
                                                value: doc.id,
                                            }))]}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name=""
                                        value={wageSelection}
                                        label={t("")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleBankChange('wage', val)}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        validations={formValidators}

                                    />
                                </div>
                            </div>
                            <div className="db-input-row">
                                <div className="db-input-holder">
                                    <FormInput
                                        id={"account_name"}
                                        onChange={(id, val) => handleFieldChange(id, val, "wage_attachment_bank")}
                                        type={"text"}
                                        value={formState.wage_attachment_bank?.account_name ?? ""}
                                        label={t("bank_account_name")}
                                        placeholder=""
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        maxLength={23}
                                    />
                                </div>
                                <div className="db-input-holder">
                                    <FormInput
                                        id={"routing_number"}
                                        onChange={(id, val) => handleFieldChange(id, val, "wage_attachment_bank")}
                                        type={"text"}
                                        value={formState.wage_attachment_bank?.routing_number ?? ""}
                                        label={t("routing_number")}
                                        validations={() => formValidators({ text: formState.wage_attachment_bank ? formState.wage_attachment_bank.routing_number : "", field: "wage_routing_number" })}
                                        placeholder=""
                                        maxLength={9}
                                        onKeyUp={allowNumbers}
                                        onKeyDown={allowNumbers}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        disabled={(wageSelection !== "new" && wageSelection !== "" ? true : false)}

                                    />
                                </div>
                            </div>
                            <div className="db-input-row">
                                <div className="db-input-holder">
                                    <FormInput
                                        id={"account_number"}
                                        onChange={(id, val) => handleFieldChange(id, val, "wage_attachment_bank")}
                                        type={"text"}
                                        value={formState.wage_attachment_bank?.account_number ?? ""}
                                        label={t("account_number")}
                                        placeholder=""
                                        maxLength={17}
                                        onKeyUp={allowNumbers}
                                        onKeyDown={allowNumbers}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        disabled={(wageSelection !== "new" && wageSelection !== "" ? true : false)}

                                    />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            }
            <div className="db-actions-row">
                <div className="">
                    {masterTaxState.error && <div className="error-holder">
                        <ApiError message={masterTaxState.error.message} onClose={handleClearError} />
                    </div>}
                    {masterTaxState.update.error && <div className="error-holder">
                        <ApiError message={masterTaxState.update.error.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="db-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("cancel")}
                            enable
                            backgroundColor={THEME.secondaryColor4}
                            onClick={handleCancel}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            loading={masterTaxState.update.loading === LoadingType.pending}
                            name={t("save")}
                            backgroundColor={THEME.buttonColor1}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisbursementBankSetupPage;
