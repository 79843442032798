import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../../components/form_selector/form_select";
import SearchBar from "../../../../../components/search_bar";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";
import { LoadingType, THEME } from "../../../../../enums";
import { addTimecardstoBatchThunk, closeBatchThunk, createBatchThunk, deleteBatchThunk, getBatchDropdownThunk, getTimeCardsListThunk, getWeekEndingDatesDropdownThunk, postSnackbarMessage, selectManageBatchState, selectProfileState, selectTimeCardsBatchState, selectTimeCardsUploadDocsUrlState, selectTimecardsListState, selectWeekEndingDatesDDList, selectWeekEndingDatesState, useAppDispatch, useAppSelector, verifyBatchThunk } from "../../../../../redux/store";
import { IBatchQueryParams, ICreateBatch, ITimeCards, ITimeCardsBatch, ITimeCardsData, ITimeCardsPayType, ITimecardsQueryParams } from "../../../../../interfaces";
import { CustomButton, FormInput, SpinnerScreen, allowNumbersWithDotAndFourDecimals, convertNumberToMoney, currencyConversion, formatNumber, formatToMoney, getPlusBtnIcon, removeLastDecimal } from "../../../../../utils";
import { getDateString } from "../../../../../variables";
import './timecards_tab.scss';
import { Portal } from "react-portal";
import VerifyBatchPopup from "../../../popups/verify_batch/verify_batch";
import { manageBatchSliceActions } from "../../../../../redux/back_office/timecards/time_cards/time_cards_manage_batch/time_cards_manage_batch_reducer";
import CloseBatchPopup from "../../../popups/close_batch/close_batch";
import DeleteBatchPopup from "../../../popups/delete_batch/delete_batch";
import { DeleteIcon, DocumentIcon, FilesIcon } from "../../../../../icons";
import UploadDocPopup from "../../../popups/upload_doc/upload_doc";
import CreateBatchPopup from "../../../popups/create_batch/create_batch";
import { timeCardsUploadUrlActions } from "../../../../../redux/back_office/timecards/time_cards/time_cards_upload_docs/time_cards_upload_docs_reducer";
import DeleteDocumentPopup from "../../../popups/delete_doc/delete_doc";
import { AppRoutes } from "../../../../../routes";
import { manageTimeCardsSliceActions } from "../../../../../redux/back_office/timecards/time_cards/manage_time_cards/manage_time_cards_reducer";
import { BatchStatus } from "../../../../../enums/back_office";
import { selectManageTimeCardState } from "../../../../../redux/back_office/timecards/time_cards/manage_time_cards/manage_time_cards_selector";
import { removeTimeCardBatchThunk, deleteTimeCardThunk } from "../../../../../redux/back_office/timecards/time_cards/manage_time_cards/manage_time_cards_thunk";


const tableHeader = [
    { title: '', code: '' },
    { title: 'batch', code: 'batch_number' },
    { title: 'status', code: 'batch_status' },
    { title: 'week_ending', code: 'week_ending_date' },
    { title: 'talent', code: 'talent_name' },
    { title: 'company', code: 'company_name' },
    { title: 'department', code: 'department_name' },
    { title: 'job_title', code: 'job_title' },
    { title: 'pay_type', code: 'pay_type' },
    { title: 'other_options', code: 'docs' },
    { title: 'pay_units', code: 'pay_units' },
    { title: 'bill_units', code: 'bill_units' },
    { title: 'pay_rate', code: 'pay_rate' },
    { title: 'bill_rate', code: 'bill_rate' },
    { title: 'item_pay', code: 'item_pay' },
    { title: 'item_bill', code: 'item_bill' },
];
interface Props extends RouteComponentProps<any> { }
interface IPayType {
    docid: string;
    payIds: string[];
}
const TimeCardsTabPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
        }`;

    const weekEndingDatesListState = useAppSelector((state) => selectWeekEndingDatesState(state));
    const weekEndingDatesList = useAppSelector((state) => selectWeekEndingDatesDDList(state));
    const batchDropdownState = useAppSelector((state) => selectTimeCardsBatchState(state));
    const timeCardsListState = useAppSelector((state) => selectTimecardsListState(state));
    const manageBatchState = useAppSelector((state) => selectManageBatchState(state));
    const manageTimeCardsState = useAppSelector((state) => selectManageTimeCardState(state));
    const timeCardsUploadDocsState = useAppSelector((state) => selectTimeCardsUploadDocsUrlState(state));

    const [selectedWeekDate, setSelectedWeekDate] = useState<{ id: string, date: string }>({ id: "", date: "" });
    const [selectedBatch, setSelectedBatch] = useState<{ id: string, batchNum?: string, status: BatchStatus | null }>({ id: "", batchNum: "", status: null });
    const [timeCardsList, setTimeCardsList] = useState<ITimeCards | null>(null);
    const [formState, setFormState] = useState<ITimeCardsData[] | null>(null);
    const [createBatch, setCreateBatch] = useState<boolean>(false);
    const [verifyBatch, setVerifyBatch] = useState<boolean>(false);
    const [closeBatch, setCloseBatch] = useState<boolean>(false);
    const [deleteBatch, setDeleteBatch] = useState<boolean>(false);
    const [attachDoc, setAttachDoc] = useState<{ visible: boolean, pay_type_id?: string, timecard_id?: string, isNew?: boolean, isClosedBatch?: boolean }>({ visible: false, pay_type_id: "", timecard_id: "", isNew: true });
    const [openTimeCardBatchDeletePopup, setOpenTimeCardBatchDeletePopup] = useState<{ visible: boolean, timecard_id?: string, batch_id?: string, batchNum?: string } | null>({ visible: false, timecard_id: "", batch_id: "", batchNum: "" })
    const [openTimeCardDeletePopup, setOpenTimeCardDeletePopup] = useState<{ visible: boolean, timecard_id?: string } | null>({ visible: false, timecard_id: "" })
    const [showItemMenu, setShowItemMenu] = useState<boolean>(false)

    const [search, setSearch] = useState('');
    const [batchList, setBatchList] = useState<ITimeCardsBatch[] | null>([]);
    const [billUnitsPayUnits, setBillPayUnits] = useState<boolean>(true);
    const isBatchSelected = selectedBatch.id !== "";

    const [snackBarMessage, setSnackBarMessage] = useState("");

    const [queryParamState, setQueryParamState] = useState<ITimecardsQueryParams>({
        show_only_unentered_time_cards: false,
        batch_id: ""
    });
    const [batchQueryParamState, setBatchQueryParamState] = useState<IBatchQueryParams>({
        show_all_batches: false,
        show_batches_from_other_user: false,
        closed_batches: false
    });
    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);

    useEffect(() => {
        if (weekEndingDatesListState && weekEndingDatesListState.loading === LoadingType.succeeded && weekEndingDatesList.length > 0) {
            setSelectedWeekDate({ id: weekEndingDatesList[0].id, date: getDateString(weekEndingDatesList[0].week_ending_date, "mm/dd/yyyy") });
            getBatchTimeCards(weekEndingDatesList[0].id)
        }
    }, [weekEndingDatesList.length, weekEndingDatesListState.loading]);
    useEffect(() => {
        if (timeCardsListState && timeCardsListState.loading === LoadingType.succeeded) {
            if (isBatchSelected) {
                setTimeCardsList(timeCardsListState.response);
                setFormState(timeCardsListState.response?.time_cards || []);
            } else {
                setTimeCardsList(timeCardsListState.response);
            }
        }
    }, [selectedBatch.id, timeCardsListState.loading, selectedWeekDate.id]);

    useEffect(() => {
        if (batchDropdownState && batchDropdownState.loading === LoadingType.succeeded) {
            setBatchList(batchDropdownState.response)
        }
    }, [selectedWeekDate, batchDropdownState.loading]);

    useEffect(() => {
        const { verify, close, delete: del } = manageBatchState;
        if (
            (verify && verify.loading === LoadingType.succeeded) ||
            (close && close.loading === LoadingType.succeeded) ||
            (del && del.loading === LoadingType.succeeded)
        ) {
            getBatchTimeCards(selectedWeekDate.id);
        }
    }, [manageBatchState.close, manageBatchState.delete, manageBatchState.verify, selectedWeekDate.id]);


    useEffect(() => {
        if (manageBatchState && (
            manageBatchState.addTimecards.loading === LoadingType.succeeded)) {
            dispatch(manageBatchSliceActions.clearState());
        }
    }, [manageBatchState,
        manageBatchState.addTimecards, manageBatchState.addTimecards.loading]);
    // useEffect(() => {
    //     // Focus on the first input element when the component mounts
    //     inputRef.current?.focus();
    // }, []);

    useEffect(() => {
        if (timeCardsListState.documentDelete.loading === LoadingType.succeeded) {
            dispatch(getTimeCardsListThunk({
                week_accounting_id: selectedWeekDate.id,
                queryParams: queryParamState
            }));
        }
    }, [timeCardsListState.documentDelete.loading]);

    useEffect(() => {
        if (
            manageTimeCardsState.removeTimeCard.loading === LoadingType.succeeded ||
            manageTimeCardsState.deleteTimeCard.loading === LoadingType.succeeded
        ) {
            dispatch(manageTimeCardsSliceActions.clearRemoveBatchState());
            dispatch(manageTimeCardsSliceActions.clearDeleteTimeCardState());
            dispatch(getTimeCardsListThunk({
                week_accounting_id: selectedWeekDate?.id,
                queryParams: queryParamState
            }));
        }
    }, [manageTimeCardsState.removeTimeCard.loading, manageTimeCardsState.deleteTimeCard.loading])

    useEffect(() => {
        if (timeCardsUploadDocsState.loading === LoadingType.succeeded) {
            if (snackBarMessage !== "") {
                dispatch(postSnackbarMessage(snackBarMessage ?? null));
            }
            setAttachDoc({ visible: false })
            dispatch(getTimeCardsListThunk({
                week_accounting_id: selectedWeekDate.id,
                queryParams: queryParamState
            }));
        }
    }, [timeCardsUploadDocsState.loading]);

    useEffect(() => {
        if (formState && search !== "") {
            const filteredList = getFilteredList(false, formState) || [];
            setFormState(filteredList);
        }
    }, [search]);

    const getBatchTimeCards = (weekid: string) => {
        dispatch(getBatchDropdownThunk({ week_accounting_id: weekid, queryParams: batchQueryParamState }));
        dispatch(getTimeCardsListThunk({
            week_accounting_id: weekid,
            queryParams: queryParamState
        }));
    }

    const handleSearch = (value: string) => {
        setSearch(value);
    }

    const handleSnackBar = () => {
        setSnackBarMessage("Document Uploaded Successfully")
    }

    const handleClearUploadDocsErrror = () => {
        dispatch(timeCardsUploadUrlActions.clearTimeCardsUploadUrlStateError())
    }
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }

    const getFilteredList = (isPayType?: boolean, updatedFormState?: ITimeCardsData[] | null) => {
        let list: ITimeCardsData[] | undefined = timeCardsList?.time_cards;

        if (timeCardsList && sortedField != null) {
            list = [...timeCardsList?.time_cards].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        if (updatedFormState) {
            list = list?.map((listItem) => {
                const updatedItem = updatedFormState.find((formDoc) => formDoc.id === listItem.id);
                return updatedItem ? updatedItem : listItem;
            });
        }
        return (list)?.filter((doc => {
            const str = search.trim().toLowerCase();
            const batchFilter = doc.batch_number ? doc.batch_number.toString().trim().toLowerCase().includes(str) : false;
            const talentFilter = doc.talent_name ? doc.talent_name.trim().toLowerCase().includes(str) : false;
            const statusFilter = doc.batch_status ? doc.batch_status.trim().toLowerCase().includes(str) : false;
            const companyFilter = doc.company_name ? doc.company_name.trim().toLowerCase().includes(str) : false;
            const deptFilter = doc.department_name ? doc.department_name.trim().toLowerCase().includes(str) : false;
            const jobTitleFilter = doc.job_title ? doc.job_title.trim().toLowerCase().includes(str) : false;

            return batchFilter || talentFilter || statusFilter || companyFilter || deptFilter || jobTitleFilter;

        }));
    }
    const handleToggleChange = (id: string, val: boolean, batch?: boolean) => {
        if (batch) {
            setBatchQueryParamState({ ...batchQueryParamState, [id === "show_closed_batches" ? "show_all_batches" : id]: val });
            dispatch(getBatchDropdownThunk({
                week_accounting_id: selectedWeekDate.id,
                queryParams: { ...batchQueryParamState, [id === "show_closed_batches" ? "show_all_batches" : id]: val }
            }));
        }
        setQueryParamState({ ...queryParamState, [id]: val })
        dispatch(getTimeCardsListThunk({
            week_accounting_id: selectedWeekDate.id,
            queryParams: { ...queryParamState, [id]: val, batch_id: id === "show_only_unentered_time_cards" ? selectedBatch.id : "" }
        }));
    }
    const handleWeekEndChange = (val) => {
        const selectedWeekObject = weekEndingDatesList.find(obj => obj.id === val);
        setSelectedWeekDate({ id: val, date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy") });
        setSelectedBatch({ id: "", batchNum: "", status: null });
        setFormState(null);
        setQueryParamState({ ...queryParamState, batch_id: "" });
        getBatchTimeCards(val)
    }
    const [payTypeValues, setPayTypeValues] = useState<IPayType[]>([]);

    const handleBatchChange = (val) => {
        const batch = batchList!.find((doc) => doc.id === val);
        setQueryParamState({ ...queryParamState, batch_id: val });
        if (batch) {
            const { batch_number, status } = batch;
            setSelectedBatch({ id: val, batchNum: batch_number, status: BatchStatus[status] });
        }
        else {
            setSelectedBatch({ id: "", batchNum: "", status: null });
        }
        dispatch(getTimeCardsListThunk({
            week_accounting_id: selectedWeekDate.id,
            queryParams: { ...queryParamState, batch_id: val }
        }));
        setPayTypeValues(formState ?
            formState.map((timecard) => ({
                docid: timecard.id,
                payIds: timecard.payments.map((pay) => pay.pay_type_id),
            })) : []);
    };

    const handleFormChange = (id: string, val: string, timeCardId: string, paytypeid: string, docPayments: ITimeCardsPayType[]) => {

        if (id === "pay_rate" || id === "bill_rate") {
            val = removeLastDecimal(val);
        }
        if (formState) {
            const updatedFormState = formState.map((item) => {
                if (item.id === timeCardId) {
                    // Update the docPayments within the matching object
                    const updatedPayments = item.payments.map((payment, index) => {
                        if (payment.pay_type_id === paytypeid) {
                            let payUnitVal, payItemVal, billUnitVal, billItemVal, payRateVal, billRateVal;
                            if (id === "pay_units") {
                                payUnitVal = val;
                                billUnitVal = payment.bill_units;
                                payRateVal = payment.pay_rate;
                                billRateVal = payment.bill_rate;
                                if (billUnitsPayUnits) {
                                    billUnitVal = val ? val : 0;
                                    payRateVal = payment.pay_rate;
                                    billRateVal = payment.bill_rate;
                                }

                            } else if (id === "bill_units") {
                                billUnitVal = val;
                                payUnitVal = payment.pay_units;
                                payRateVal = payment.pay_rate;
                                billRateVal = payment.bill_rate;
                            }
                            else if (id === "pay_rate") {
                                payRateVal = val;
                                billRateVal = payment.bill_rate;
                                payUnitVal = payment.pay_units;
                                billUnitVal = payment.bill_units;
                            }
                            else if (id === "bill_rate") {
                                payRateVal = payment.pay_rate;
                                billRateVal = val;
                                payUnitVal = payment.pay_units;
                                billUnitVal = payment.bill_units;
                            }
                            return {
                                ...payment,
                                pay_units: payUnitVal,
                                bill_units: billUnitVal,
                                pay_rate: payRateVal,
                                bill_rate: billRateVal,
                                item_pay: payUnitVal.toString().replace(/,/g, '') !== "" ? convertNumberToMoney(payRateVal.toString().replace(/,/g, '') * parseFloat(payUnitVal.toString().replace(/,/g, '')), "number") as number : 0,

                                item_bill: billUnitVal.toString().replace(/,/g, '') !== "" ? convertNumberToMoney(billRateVal.toString().replace(/,/g, '') * parseFloat(billUnitVal.toString().replace(/,/g, '')), "number") as number : 0
                            };
                        }
                        return payment;
                    });

                    return {
                        ...item,
                        payments: updatedPayments
                    };
                }
                return item;
            });
            setFormState(updatedFormState);
        }
    };

    const handlePayTypeChange = (parentId: string, val) => {
        if (val.length === 0) {
            return;
        }
        const index = payTypeValues.findIndex(item => item.docid === parentId);

        if (index !== -1) {
            // If an object with parentId exists, update its payIds
            const updatedPayTypeValues = [...payTypeValues];
            updatedPayTypeValues[index] = { docid: parentId, payIds: val };
            setPayTypeValues(updatedPayTypeValues);
        } else {
            // If an object with parentId does not exist, create a new object and add it to payTypeValues
            setPayTypeValues(prevState => [...prevState, { docid: parentId, payIds: val }]);
        }
        const selectedFormIndex = formState?.findIndex((doc) => doc.id === parentId);
        if (formState && selectedFormIndex !== undefined && selectedFormIndex !== -1) {
            const updatedFormState = [...formState];
            const selectedFormDoc = { ...updatedFormState[selectedFormIndex] };

            const updatedPayments = selectedFormDoc.payments.map((payment) => {
                if (val.includes(payment.pay_type_id)) {
                    return {
                        ...payment,
                        selected: true
                    };
                } else {
                    return {
                        ...payment,
                        selected: false
                    };
                }
            });

            selectedFormDoc.payments = updatedPayments;
            updatedFormState[selectedFormIndex] = selectedFormDoc;
            setFormState(updatedFormState);
            getFilteredList(true, updatedFormState)
        }
    }
    const handleVerifyBatch = () => {
        if (formState) {
            dispatch(verifyBatchThunk(selectedBatch.id));
            setVerifyBatch(true);
        }
    };
    const handleCloseBatch = () => {
        if (selectedBatch) {
            dispatch(closeBatchThunk(selectedBatch.id));
            setCloseBatch(true)
        }
    };
    const handleDeleteBatch = () => {
        if (selectedBatch) {
            dispatch(deleteBatchThunk(selectedBatch.id));
            setDeleteBatch(true);
        }
    };

    const handleCreateBatch = (doc: ICreateBatch) => {
        if (doc) {
            dispatch(createBatchThunk(doc))
        };
    }
    const handleCreateClose = () => {
        setCreateBatch(false);
        dispatch(manageBatchSliceActions.clearCreateState());
        dispatch(manageBatchSliceActions.clearaddTCBErrorState())
    }
    const handleCreateSuccessClose = () => {
        handleCreateClose();
        getBatchTimeCards(selectedWeekDate.id)
    }

    const handleOpenItemMenu = (visible: boolean, timecard_id: string, batch_id: string, batch_number: string) => {
        setShowItemMenu(!showItemMenu)
        setOpenTimeCardBatchDeletePopup({ visible: visible, timecard_id: timecard_id, batch_id: batch_id, batchNum: batch_number })
    }

    const handleOpenTimeCardBatchDelete = (visible: boolean, timecard_id: string, batch_id: string, batch_number: string) => {
        setOpenTimeCardBatchDeletePopup({ visible: visible, timecard_id: timecard_id, batch_id: batch_id, batchNum: batch_number })
    }

    const handleOpenTimeCardDelete = (visible: boolean, timecard_id: string) => {
        setOpenTimeCardDeletePopup({ visible: visible, timecard_id: timecard_id })
    }

    function handleOnDelete(timeCardId: string, batchId: string) {
        dispatch(removeTimeCardBatchThunk({ time_card_id: timeCardId, batch_id: batchId }))
    }
    function handleOnTimeCardDelete(timeCardId: string) {
        dispatch(deleteTimeCardThunk(timeCardId))
    }

    function handleDeleteTCSSuccessClose() {
        setOpenTimeCardBatchDeletePopup(null);
    }
    function handleDeleteTimeCardSuccessClose() {
        setOpenTimeCardBatchDeletePopup(null);
    }

    const handleDeleteTCSClose = () => {
        setOpenTimeCardBatchDeletePopup(null);
    }
    const handleDeleteTimecardClose = () => {
        setOpenTimeCardDeletePopup(null);
    }

    function handleClearTCSError() {
        dispatch(manageTimeCardsSliceActions.clearRemoveBatchErrorState());
    }
    function handleClearTimeCardError() {
        dispatch(manageTimeCardsSliceActions.clearDeleteTimeCardErrorState());
    }


    const handleAddToBatch = async (doc: ITimeCardsData, paytypeid: string) => {
        // const currentObj = formState?.find((obj) => obj.id === id);
        if (doc) {
            const isAnyPaymentNonZero = doc.payments
                .filter(payment => payment.pay_type_id === paytypeid)
                .some(payment => {
                    if (doc.direct_hire) {
                        return parseFloat(payment.bill_units.toString()) !== 0 && payment.bill_units.toString() !== ""
                    }
                    else {
                        return (parseFloat(payment.pay_units.toString()) !== 0 && payment.pay_units.toString() !== "")
                            || (parseFloat(payment.bill_units.toString()) !== 0 && payment.bill_units.toString() !== "")
                    }
                });
            if (isAnyPaymentNonZero) {
                const newPayments = doc.payments.map((payment) => {
                    return {
                        ...payment,
                        pay_units: payment.pay_units ? convertNumberToMoney(payment.pay_units, "number") as number : 0,
                        bill_units: payment.bill_units ? convertNumberToMoney(payment.bill_units, "number") as number : 0,
                        pay_rate: payment.pay_rate ? convertNumberToMoney(payment.pay_rate, "number") as number : 0,
                        bill_rate: payment.bill_rate ? convertNumberToMoney(payment.bill_rate, "number") as number : 0,
                        item_pay: payment.item_pay ? convertNumberToMoney(payment.item_pay, "number") as number : 0,
                        item_bill: payment.item_bill ? convertNumberToMoney(payment.item_bill, "number") as number : 0,
                    };
                })
                await dispatch(addTimecardstoBatchThunk({
                    doc: {
                        ...doc,
                        payments: newPayments,
                        batch_id: selectedBatch.id
                    }
                }));
                if (formState) {
                    // setSelectedBatch({ ...selectedBatch, status: selectedBatch.status === BatchStatus.VERIFIED ? BatchStatus.OPEN : selectedBatch.status })
                    const updatedFormState = formState.map((item) => {
                        if (item.id === doc.id && selectedBatch.batchNum) {
                            return {
                                ...item,
                                batch_id: selectedBatch.id,
                                batch_number: selectedBatch.batchNum,
                                // batch_status: selectedBatch.status === BatchStatus.VERIFIED ? BatchStatus.OPEN : selectedBatch.status,
                                payments: item.payments.map((payment) => {
                                    // Convert pay_rate to a number before updating
                                    const payUnitsNum = convertNumberToMoney(payment.pay_units, "number") as number;
                                    const billUnitsNum = convertNumberToMoney(payment.bill_units, "number") as number;
                                    const payRateNum = convertNumberToMoney(payment.pay_rate, "number") as number;
                                    const billRateNum = convertNumberToMoney(payment.bill_rate, "number") as number;
                                    const itemBill = convertNumberToMoney(payment.item_bill, "number") as number;
                                    const itemPay = convertNumberToMoney(payment.item_pay, "number") as number;

                                    return {
                                        ...payment,
                                        pay_units: payUnitsNum, // Update pay_units with the converted value
                                        bill_units: billUnitsNum, // Update bill_units with the converted value
                                        pay_rate: payRateNum, // Update pay_rate with the converted value
                                        bill_rate: billRateNum, // Update bill_rate with the converted value
                                        item_bill: itemBill, // Update item_bill with the converted value
                                        item_pay: itemPay, // Update item_pay with the converted value

                                    };
                                })
                            };
                        }
                        return item;
                    });
                    setFormState(updatedFormState);
                }
            }

        }
    }

    const getCustomFormSelect = (payments: ITimeCardsPayType[], id: string, batch_id: string, customWidth?: true, customMargin?: true) => {
        return (
            <>
                <CustomMultiFormSelect
                    name={'add_pay_type'}
                    list={payments?.map((doc) => ({
                        label: doc.pay_type,
                        value: doc.pay_type_id,
                    }))}
                    onChange={(value) => handlePayTypeChange(id, value)}
                    required={true}
                    placeholder={t('select_option')}
                    // value={isBatchSelected && payTypeValues.some((item) => item.docid === id) ? payTypeValues.filter((pay) => pay.docid === id).flatMap((doc) => doc.payIds) : payments.filter((doc) => doc.selected).map((doc) => doc.pay_type_id)}
                    value={payments.filter((doc) => doc.selected).map((doc) => doc.pay_type_id)}
                    customStyle={{ width: customWidth ? "18vw" : '-webkit-fill-available', margin: customMargin ? "0 0 1em 1em" : "" }}
                    disabled={!isBatchSelected}
                    isClearable={false}
                    menuPlacement="top"

                />
            </>
        )
    }
    const handleTalentSelect = (talentId: string, talentName: string) => {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${talentId}`,
            state: {
                id: talentId,
                name: talentName,
            },
        });
        return;
    }

    const handleCompanySelect = (companyId: string, companyName: string) => {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${companyId}`,
            state: {
                id: companyId,
                name: companyName,
            },
        });
        return;
    }
    const handleDepartmentSelect = (companyId: string, companyName: string, deptId: string, deptName: string) => {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${companyId}/dashboard/${deptId}`,
            state: {
                id: companyId,
                name: companyName,
                departmentId: deptId,
                departmentName: deptName,
            },
        });
        return;
    }
    const handleJobSelect = (jobId: string, jobName: string) => {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${jobId}`,
            state: {
                id: jobId,
                name: jobName,
            }
        })
        return;
    };
    const handleManageBatchSuccessClose = () => {
        setSelectedBatch({ id: "", batchNum: "", status: null });
    }
    function handleMouseOver(timecard_id?: string,) {
        setShowItemMenu(true);
        setOpenTimeCardBatchDeletePopup({ visible: false, timecard_id: timecard_id })
    }
    function handleMouseOut() {
        setShowItemMenu(false);
    }
    return (
        <div className="timecards-tab-container">
            <div className="timecards-tab-header">
                <div className="col1">
                    <CustomFormSelect
                        list={
                            weekEndingDatesList.map((doc) => ({
                                label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                value: doc.id,
                            }))
                        }
                        customStyle={{ width: "100%" }}
                        name={"week_ending_date"}
                        value={selectedWeekDate.id}
                        label={t("week_ending_date")}
                        placeholder={"Select"}
                        onChange={handleWeekEndChange}
                        isClearable={false}
                    />
                    <CustomFormSelect
                        list={batchList!.map((doc) => ({
                            label: doc.batch_number,
                            value: doc.id
                        }))}
                        customStyle={{ width: "100%" }}
                        name={"select_batch"}
                        value={selectedBatch.id}
                        label={t("select_batch")}
                        placeholder={"Select"}
                        onChange={(val) => handleBatchChange(val)}
                    />
                </div>
                <div className="col2">
                    <div>
                        <ToggleSwitch
                            onChange={(value) => handleToggleChange("show_closed_batches", value, true)}
                            label={t("show_closed_batches")}
                            checked={queryParamState.show_closed_batches!}
                        />
                        <ToggleSwitch
                            onChange={(value) => handleToggleChange("show_batches_from_other_user", value, true)}
                            label={t("show_batched_from_other_users")}
                            checked={batchQueryParamState.show_batches_from_other_user!}
                        />
                    </div>
                    <div className="toggle-col2">
                        <ToggleSwitch
                            onChange={(value) => setBillPayUnits(value)}
                            label={t("bill_units_pay_units")}
                            checked={billUnitsPayUnits!}
                        />
                        <ToggleSwitch
                            onChange={(value) => handleToggleChange("show_only_unentered_time_cards", value)}
                            label={t("show_only_unentered_timecards")}
                            checked={queryParamState.show_only_unentered_time_cards!}
                        />
                    </div>
                </div>
                <div className="col3">
                    <div className="col3-row1">
                        <div>
                            <CustomButton
                                leftIcon={getPlusBtnIcon()}
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("create_new_batch")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => { setCreateBatch(true) }}
                            />
                        </div>
                        <div>
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("verify_batch")}
                                enable={isBatchSelected && selectedBatch.status !== BatchStatus.CLOSED}
                                backgroundColor={THEME.statusActiveColor}
                                onClick={handleVerifyBatch}
                            />
                        </div>
                        <div>
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("close_batch")}
                                enable={isBatchSelected}
                                backgroundColor={THEME.statusDisabledColor}
                                onClick={handleCloseBatch}
                            />
                        </div>
                        <div>
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("delete_batch")}
                                enable={isBatchSelected}
                                backgroundColor={THEME.statusInActiveColor}
                                onClick={() => { setDeleteBatch(true) }}
                            />
                        </div>
                    </div>
                    <div className="search-bar">
                        <SearchBar value={search} onChange={handleSearch} onSearch={() => { }} placeholder="Search..." />
                    </div>
                </div>
            </div>
            <div className="timecards-tab-content">
                <div>
                    {timeCardsListState && timeCardsListState.loading === LoadingType.pending ?
                        (
                            <>
                                <SpinnerScreen />
                            </>
                        )
                        :
                        (
                            <>
                                <div className="total-count" style={{ marginBottom: "0.5rem", }}>
                                    <span>
                                        {`${t("total_count")}: `}
                                        <span className="total-count-number"><b>{getFilteredList()?.length}</b></span>
                                    </span>
                                </div>
                                <div className="timecards-table" key="1">
                                    <SortableTable
                                        headerList={tableHeader}
                                        sortedField={sortedField}
                                        onSortChange={handleSortFieldChange}
                                        flexNumber={getFlexNumber}
                                        isAsc={sortDirection}
                                    >
                                        {getFilteredList() && getFilteredList()!.length > 0 ?
                                            getFilteredList(undefined, formState)?.map((doc, index) => {
                                                const thisBatch = doc.batch_id === selectedBatch.id;
                                                return (
                                                    <>
                                                        {(doc.payments.filter((pay) => pay.selected)).map((subDoc, subIndex) => {
                                                            const rowSubIndex = (doc.payments.filter((pay) => pay.selected)).length === 1 ? subIndex + 1 : subIndex;
                                                            return (
                                                                <>
                                                                    <tr key={doc.id + index + subIndex} className={index % 2 === 0 ? 'tr-even' : 'tr-odd'}>

                                                                        {(subIndex === 0) ?
                                                                            <>
                                                                                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                                                    {doc.batch_status !== BatchStatus.CLOSED &&
                                                                                        <div key={index} className="del-tmecard"
                                                                                            onMouseOut={handleMouseOut}
                                                                                            onMouseOver={() => handleMouseOver(doc.id)}
                                                                                        >

                                                                                            <DeleteIcon className="delete-icon" onClick={() => handleOpenItemMenu(false, doc.id, doc.batch_id, doc.batch_number)} />
                                                                                            {showItemMenu &&
                                                                                                <>
                                                                                                    {openTimeCardBatchDeletePopup?.timecard_id === doc.id && (
                                                                                                        <div className="parent-position">
                                                                                                            <div className="child-position">
                                                                                                                <div className="item-menu-title">
                                                                                                                    {doc.batch_id && <div onClick={() => handleOpenTimeCardBatchDelete(true, doc.id, doc.batch_id, doc.batch_number)} className="rm-f-batch">{t("remove_from_batch")}</div>}
                                                                                                                    <div onClick={() => handleOpenTimeCardDelete(true, doc.id)}>{t("delete_time_card")}</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </TableData>
                                                                                <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.batch_number}</span></TableData>
                                                                                <TableData customStyle={{ flex: getFlexNumber(2) }}><span style={{ color: doc.batch_status === BatchStatus.OPEN ? THEME.statusInActiveColor : (doc.batch_status === BatchStatus.VERIFIED ? THEME.statusActiveColor : THEME.statusDisabledColor) }}>{doc.batch_status}</span></TableData>
                                                                                <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{getDateString(doc.week_ending_date, "mm/dd/yyyy")}</span></TableData>
                                                                                <TableData customStyle={{ flex: getFlexNumber(4) }} onClick={() => handleTalentSelect(doc.talent_id, doc.talent_name)} isButton={true}><span>{doc.talent_name}</span></TableData>
                                                                                <TableData customStyle={{ flex: getFlexNumber(5) }} onClick={() => handleCompanySelect(doc.company_id, doc.company_name)} isButton={true}><span>{doc.company_name}</span></TableData>
                                                                                <TableData customStyle={{ flex: getFlexNumber(6) }} onClick={() => handleDepartmentSelect(doc.company_id, doc.company_name, doc.department_id, doc.department_name)} isButton={true}><span>{doc.department_name ? doc.department_name : 'Corporate'}</span></TableData>
                                                                                <TableData customStyle={{ flex: getFlexNumber(7) }} onClick={() => handleJobSelect(doc.job_order_id, doc.job_title)} isButton={true}><span>{doc.job_title}</span></TableData></>
                                                                            :
                                                                            (<>
                                                                                <TableData customStyle={{ flex: getFlexNumber(16) }}  >
                                                                                    {rowSubIndex === 1 ?
                                                                                        <>
                                                                                            {getCustomFormSelect(doc.payments, doc.id, doc.batch_id, true, true)}

                                                                                        </>
                                                                                        :
                                                                                        <span></span>
                                                                                    }
                                                                                </TableData>
                                                                            </>)
                                                                        }
                                                                        <TableData customStyle={{ flex: getFlexNumber(8) }}><span>{subDoc.pay_type}</span></TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                                                            <div>
                                                                                {subDoc.docs.length !== 0 ?

                                                                                    <CustomButton
                                                                                        leftIcon={<div className="btn-icon">
                                                                                            <FilesIcon width={"100%"} height={"100%"} />
                                                                                        </div>
                                                                                        }
                                                                                        loading={false}
                                                                                        textStyle={{ textTransform: "capitalize" }}
                                                                                        name={t("docs_attached")}
                                                                                        enable={doc.batch_status === BatchStatus.CLOSED ? true : isBatchSelected}
                                                                                        onClick={() => { setAttachDoc({ visible: true, pay_type_id: subDoc.pay_type_id, timecard_id: doc.id, isNew: false, isClosedBatch: doc.batch_status === BatchStatus.CLOSED ? true : false }) }}
                                                                                        customClassName="attach_doc_btn"
                                                                                    /> :
                                                                                    <CustomButton
                                                                                        leftIcon={<div className="btn-icon">
                                                                                            <FilesIcon width={"100%"} height={"100%"} />
                                                                                        </div>
                                                                                        }
                                                                                        loading={false}
                                                                                        textStyle={{ textTransform: "capitalize" }}
                                                                                        name={t("attach_docs")}
                                                                                        enable={isBatchSelected && doc.batch_status !== BatchStatus.CLOSED}
                                                                                        onClick={() => { setAttachDoc({ visible: true, pay_type_id: subDoc.pay_type_id, timecard_id: doc.id, isNew: true }) }}
                                                                                        customClassName="attach_doc_btn"
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                                                            <div>
                                                                                {formState && isBatchSelected && doc.batch_status !== BatchStatus.CLOSED && !doc.direct_hire ?
                                                                                    <div className="form-input-holder">
                                                                                        <FormInput
                                                                                            id={"pay_units"}
                                                                                            onChange={(id, val) => (handleFormChange(id, val, doc.id, subDoc.pay_type_id, doc.payments))}
                                                                                            type={"text"}
                                                                                            step={"0.01"}
                                                                                            min={"0.00"}
                                                                                            value={formState.find((formDoc) => formDoc.id === doc.id)?.payments.find(payment => payment.pay_type_id === subDoc.pay_type_id)?.pay_units}
                                                                                            customStyle={{ width: '3vw' }}
                                                                                            placeholder="0"
                                                                                            onBlur={() => handleAddToBatch(doc, subDoc.pay_type_id)}
                                                                                            onKeyUp={(e) => allowNumbersWithDotAndFourDecimals(e)}
                                                                                            onKeyDown={(e) => allowNumbersWithDotAndFourDecimals(e)}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <span>{subDoc.pay_units}</span>}
                                                                            </div>
                                                                        </TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(11) }}>
                                                                            <div >
                                                                                {formState && isBatchSelected && doc.batch_status !== BatchStatus.CLOSED ?
                                                                                    <div className="form-input-holder">
                                                                                        <FormInput
                                                                                            id={"bill_units"}
                                                                                            onChange={(id, val) => (handleFormChange(id, val, doc.id, subDoc.pay_type_id, doc.payments))}
                                                                                            type={"text"}
                                                                                            step={"0.01"}
                                                                                            min={"0.00"}
                                                                                            value={formState.find((formDoc) => formDoc.id === doc.id)?.payments.find(payment => payment.pay_type_id === subDoc.pay_type_id)?.bill_units}
                                                                                            customStyle={{ width: '3vw' }}
                                                                                            placeholder="0"
                                                                                            onBlur={() => handleAddToBatch(doc, subDoc.pay_type_id)}
                                                                                            onKeyUp={(e) => allowNumbersWithDotAndFourDecimals(e)}
                                                                                            onKeyDown={(e) => allowNumbersWithDotAndFourDecimals(e)}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <span>{subDoc.bill_units}</span>}
                                                                            </div>
                                                                        </TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(12) }}>
                                                                            <div className="tt-form-input">
                                                                                {formState && isBatchSelected && doc.batch_status !== BatchStatus.CLOSED && !doc.direct_hire ?
                                                                                    <div className="form-input-holder">
                                                                                        <FormInput
                                                                                            id={"pay_rate"}
                                                                                            onChange={(id, val) => (handleFormChange(id, val, doc.id, subDoc.pay_type_id, doc.payments))}
                                                                                            type={"text"}
                                                                                            value={convertNumberToMoney(formState.find((formDoc) => formDoc.id === doc.id)?.payments.find(payment => payment.pay_type_id === subDoc.pay_type_id)?.pay_rate, "string", 4) as string}
                                                                                            customStyle={{ width: '4vw' }}
                                                                                            placeholder=""
                                                                                            prefix={<span>$</span>}
                                                                                            step={"0.01"}
                                                                                            min={"0.00"}
                                                                                            onKeyUp={(event) => formatToMoney(event, 4)}
                                                                                            onKeyDown={(event) => formatToMoney(event, 4)} // Handle Tab key press event
                                                                                            onBlur={(event) => { formatToMoney(event, 4); handleAddToBatch(doc, subDoc.pay_type_id) }}

                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <span>{subDoc.pay_rate
                                                                                        ? currencyConversion(subDoc.pay_rate)
                                                                                        : "$0.00"}</span>
                                                                                }
                                                                            </div>
                                                                        </TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(13) }}>
                                                                            <div className="tt-form-input">
                                                                                {formState && isBatchSelected && doc.batch_status !== BatchStatus.CLOSED ?
                                                                                    <div className="form-input-holder">
                                                                                        <FormInput
                                                                                            id={"bill_rate"}
                                                                                            onChange={(id, val) => (handleFormChange(id, val, doc.id, subDoc.pay_type_id, doc.payments))}
                                                                                            type={"text"}
                                                                                            value={convertNumberToMoney(formState.find((formDoc) => formDoc.id === doc.id)?.payments.find(payment => payment.pay_type_id === subDoc.pay_type_id)?.bill_rate, "string", 4) as string}
                                                                                            customStyle={{ width: '4vw' }}
                                                                                            placeholder=""
                                                                                            prefix={<span>$</span>}
                                                                                            step={"0.01"}
                                                                                            min={"0.00"}
                                                                                            onKeyDown={(event) => formatToMoney(event, 4)}
                                                                                            onKeyUp={(event) => formatToMoney(event, 4)} // Handle Tab key press event
                                                                                            onBlur={(event) => { handleAddToBatch(doc, subDoc.pay_type_id); formatToMoney(event, 4); }}

                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <span>{subDoc.bill_rate
                                                                                        ? currencyConversion(subDoc.bill_rate)
                                                                                        : "$0.00"}</span>
                                                                                }
                                                                            </div>
                                                                        </TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(14) }}>
                                                                            <span>
                                                                                {formState && thisBatch
                                                                                    ? (!isNaN(subDoc.pay_units) && !isNaN(parseInt(subDoc.pay_rate.toString().replace(/,/g, '')))) ? currencyConversion(subDoc.pay_units * parseFloat(subDoc.pay_rate.toString().replace(/,/g, '')), 4) : currencyConversion(subDoc.item_pay, 4)
                                                                                    : currencyConversion(subDoc.item_pay, 4)}
                                                                            </span>
                                                                        </TableData>

                                                                        <TableData customStyle={{ flex: getFlexNumber(15) }}><span>
                                                                            {formState && thisBatch
                                                                                ? (!isNaN(subDoc.bill_units) && !isNaN(parseInt(subDoc.bill_rate.toString().replace(/,/g, '')))) ? currencyConversion(subDoc.bill_units * parseFloat(subDoc.bill_rate.toString().replace(/,/g, '')), 4) : currencyConversion(subDoc.item_bill, 4)
                                                                                : currencyConversion(subDoc.item_bill, 4)}
                                                                        </span></TableData>
                                                                    </tr>
                                                                    <tr>
                                                                        {(subIndex === 0) && rowSubIndex === 1 &&
                                                                            <>
                                                                                {getCustomFormSelect(doc.payments, doc.id, doc.batch_id, true, true)}
                                                                            </>
                                                                        }

                                                                    </tr>
                                                                </>
                                                            )

                                                        })}
                                                    </>
                                                );
                                            })
                                            :
                                            <tr>
                                                <TableData customStyle={{ flex: getFlexNumber(16) }}><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_timecards_available_weekend")}</div></TableData>
                                            </tr>
                                        }
                                    </SortableTable >
                                </div>
                                {timeCardsListState.loading === LoadingType.succeeded &&
                                    <div>
                                        <table className='total-table'>
                                            <tbody>
                                                <tr className="total-row">
                                                    <td style={{ flex: getFlexNumber(0) }}><span></span></td>
                                                    <td style={{ flex: getFlexNumber(1) }}><span></span></td>
                                                    <td style={{ flex: getFlexNumber(2) }}><span></span></td>
                                                    <td style={{ flex: getFlexNumber(3) }}><span></span></td>
                                                    <td style={{ flex: getFlexNumber(4) }}><span></span></td>
                                                    <td style={{ flex: getFlexNumber(5) }}><span></span></td>
                                                    <td style={{ flex: getFlexNumber(6) }}><span></span></td>
                                                    <td style={{ flex: getFlexNumber(7) }}><span></span></td>
                                                    <td style={{ flex: getFlexNumber(8) }}><span></span></td>
                                                    <td style={{ flex: getFlexNumber(9) }}><span>{"Total Units"}</span></td>
                                                    <td style={{ flex: getFlexNumber(10) }}><span>
                                                        {((isBatchSelected ? (formState?.length || 0) : (getFilteredList()?.length || 0)) > 0 ?
                                                            formatNumber((isBatchSelected ? formState : getFilteredList())?.reduce(function (prev: any, current: any) {
                                                                return prev + current.payments.reduce((acc: number, payment: any) => {
                                                                    return acc + parseFloat(payment.pay_units);
                                                                }, 0);
                                                            }, 0)
                                                            ) : 0)}
                                                    </span></td>
                                                    <td style={{ flex: getFlexNumber(11) }}><span>
                                                        {((isBatchSelected ? (formState?.length || 0) : (getFilteredList()?.length || 0)) > 0 ?
                                                            formatNumber((isBatchSelected ? formState : getFilteredList())?.reduce(function (prev: any, current: any) {
                                                                return prev + current.payments.reduce((acc: number, payment: any) => {
                                                                    return acc + parseFloat(payment.bill_units);
                                                                }, 0);
                                                            }, 0)
                                                            ) : 0)}
                                                    </span></td>
                                                    <td style={{ flex: getFlexNumber(12) }}><span>
                                                    </span></td>
                                                    <td style={{ flex: getFlexNumber(13) }}><span></span></td>

                                                    <td style={{ flex: getFlexNumber(14) }}><span>
                                                        {((isBatchSelected ? (formState?.length || 0) : (getFilteredList()?.length || 0)) > 0 ?
                                                            currencyConversion((isBatchSelected ? formState : getFilteredList())?.reduce(function (prev: any, current: any) {
                                                                return prev + current.payments.reduce((acc: number, payment: any) => {
                                                                    return acc + payment.item_pay;
                                                                }, 0);
                                                            }, 0)
                                                            ) : 0)}</span></td>
                                                    <td style={{ flex: getFlexNumber(15) }}><span>
                                                        {((isBatchSelected ? (formState?.length || 0) : (getFilteredList()?.length || 0)) > 0 ?
                                                            currencyConversion((isBatchSelected ? formState : getFilteredList())?.reduce(function (prev: any, current: any) {
                                                                return prev + current.payments.reduce((acc: number, payment: any) => {
                                                                    return acc + payment.item_bill;
                                                                }, 0);
                                                            }, 0)) : 0)}</span></td>


                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                }
                            </>
                        )
                    }
                </div>
            </div>
            {createBatch &&
                <Portal>
                    <CreateBatchPopup
                        visible={createBatch}
                        title={t("create_batch")}
                        onClose={handleCreateClose}
                        onSuccessClose={handleCreateSuccessClose}
                        batchNum={selectedBatch.batchNum}
                        createState={manageBatchState.create}
                        addTimecardsState={manageBatchState.create.loading === LoadingType.succeeded ? manageBatchState.addTimecards : null}
                        weekEndingDatesList={weekEndingDatesList}
                        handleCreateBatch={handleCreateBatch}
                        currentLoginUserName={currentLoginUserName}
                    />
                </Portal>
            }
            {verifyBatch &&
                <Portal>
                    <VerifyBatchPopup
                        visible={verifyBatch}
                        title={t("verify_batch")}
                        onClose={() => { setVerifyBatch(false); dispatch(manageBatchSliceActions.clearVerifyState()) }}
                        onSuccessClose={() => { }}
                        batchNum={selectedBatch.batchNum}
                        verifyState={manageBatchState.verify}
                    />
                </Portal>
            }
            {closeBatch &&
                <Portal>
                    <CloseBatchPopup
                        visible={closeBatch}
                        title={t("close_batch")}
                        onClose={() => { setCloseBatch(false); dispatch(manageBatchSliceActions.clearCloseState()) }}
                        batchNum={selectedBatch.batchNum}
                        closeState={manageBatchState.close}
                        onSuccessClose={handleManageBatchSuccessClose}
                    />
                </Portal>
            }
            {deleteBatch &&
                <Portal>
                    <DeleteBatchPopup
                        visible={deleteBatch}
                        title={t("delete_batch")}
                        onClose={() => { setDeleteBatch(false); dispatch(manageBatchSliceActions.clearDeleteState()) }}
                        batchNum={selectedBatch.batchNum}
                        deleteState={manageBatchState.delete}
                        handleDeleteBatch={handleDeleteBatch}
                        onSuccessClose={handleManageBatchSuccessClose}

                    />
                </Portal>
            }
            {attachDoc.visible &&
                <Portal>
                    <UploadDocPopup
                        visible={attachDoc.visible}
                        onClose={() => { setAttachDoc({ visible: false }) }}
                        onClearError={handleClearUploadDocsErrror}
                        pay_type_id={attachDoc?.pay_type_id}
                        timecard_id={attachDoc?.timecard_id}
                        error={timeCardsUploadDocsState?.error}
                        loading={timeCardsUploadDocsState?.loading}
                        deleteStateError={timeCardsListState.documentDelete.error}
                        deleteStateLoading={timeCardsListState.documentDelete.loading}
                        timeCardsList={timeCardsList}
                        isNew={attachDoc.isNew}
                        handleSnackBar={handleSnackBar}
                        isClosedBatch={attachDoc.isClosedBatch}
                    />
                </Portal>
            }
            {openTimeCardBatchDeletePopup && openTimeCardBatchDeletePopup?.visible && (<Portal>
                <DeleteDocumentPopup
                    visible={openTimeCardBatchDeletePopup?.visible}
                    onClose={handleDeleteTCSClose}
                    handleClearError={handleClearTCSError}
                    onConfirm={() => handleOnDelete(openTimeCardBatchDeletePopup?.timecard_id!, openTimeCardBatchDeletePopup?.batch_id!)}
                    onDissmiss={handleDeleteTCSSuccessClose}
                    error={manageTimeCardsState.removeTimeCard.error}
                    loading={manageTimeCardsState.removeTimeCard.loading}
                    message={t("are_you_sure_to_remove_this_timecard_from_this_batch", { batchNumber: openTimeCardBatchDeletePopup?.batchNum! })}
                    headerText={t('remove_timecard')}
                    btnName={t("remove")}
                />
            </Portal>)}
            {openTimeCardDeletePopup && openTimeCardDeletePopup?.visible && (<Portal>
                <DeleteDocumentPopup
                    visible={openTimeCardDeletePopup?.visible}
                    onClose={handleDeleteTimecardClose}
                    handleClearError={handleClearTimeCardError}
                    onConfirm={() => handleOnTimeCardDelete(openTimeCardDeletePopup?.timecard_id!)}
                    onDissmiss={handleDeleteTimeCardSuccessClose}
                    error={manageTimeCardsState.deleteTimeCard.error}
                    loading={manageTimeCardsState.deleteTimeCard.loading}
                    message={t("are_you_sure_to_remove_this_timecard")}
                    headerText={t('delete_timecard')}
                    btnName={t("delete")}
                />
            </Portal>)}
        </div>
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 0.1;
        if (value === 1) return 1.2;
        if (value === 2) return 1.7;
        if (value === 3) return 2;
        if (value === 4) return 1.8;
        if (value === 5) return 2;
        if (value === 6) return 2;
        if (value === 7) return 1.8;
        if (value === 8) return 1.3;
        if (value === 9) return 2.2;
        if (value === 10) return 1.5;
        if (value === 11) return 1.5;
        if (value === 12) return 1.8;
        if (value === 13) return 1.8;
        if (value === 14) return 1.8;
        if (value === 15) return 1.8;
        if (value === 16) return 14.05;
        return 1;
    }
}

export default TimeCardsTabPage;
