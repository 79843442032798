import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./mtr_import_thunk";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import { IMTExtract } from "../../../../../interfaces/report_center";


export interface MasterTaxReportsState {
    postImport: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    reports: {
        error?: AppError | null;
        loading: LoadingType;
        response: IMTExtract[];
    },
    fileView: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
}

const initialState: MasterTaxReportsState = {
    postImport: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    reports: {
        error: null,
        loading: LoadingType.idle,
        response: [],
    },
    fileView: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
};

export const mtrImportSlice = createSlice({
    name: "mtrImportSlice",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearErrorState(state) {
            return { ...initialState, error: null };
        },
        clearPostState(state) {
            return {
                ...initialState,
                postImport: { ...initialState.postImport }
            }
        },
        clearReportsState(state) {
            return {
                ...initialState,
                reports: { ...initialState.reports }
            }
        },
        clearPostErrorState(state) {
            return {
                ...initialState,
                postImport: { ...initialState.postImport, error: null }
            }
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(thunk.postMasterTaxReportImport.pending, (state, action) => ({ ...state, postImport: { ...state.postImport, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.postMasterTaxReportImport.rejected, (state, action) => ({ ...state, postImport: { ...state.postImport, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.postMasterTaxReportImport.fulfilled, (state, action) => ({ ...state, postImport: { ...state.postImport, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getMTRdirectory.pending, (state, action) => ({ ...state, reports: { ...state.reports, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getMTRdirectory.rejected, (state, action) => ({ ...state, reports: { ...state.reports, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getMTRdirectory.fulfilled, (state, action) => ({ ...state, reports: { ...state.reports, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getMTRExtractedFile.pending, (state, action) => ({ ...state, fileView: { ...state.fileView, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getMTRExtractedFile.rejected, (state, action) => ({ ...state, fileView: { ...state.fileView, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getMTRExtractedFile.fulfilled, (state, action) => ({ ...state, fileView: { ...state.fileView, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    },
});
export const mtrImportSliceActions = mtrImportSlice.actions;
export const selectState = (state: MasterTaxReportsState) => state;
