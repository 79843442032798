import { useTranslation } from "react-i18next";
import { postMasterTaxReportImport, postSnackbarMessage, selectMTRState, selectProfileState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { CustomButton, enforceFormat, formatNumber, FormInput, mapOptions } from "../../../../../utils";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { useEffect, useState } from "react";
import { IMTExtract } from "../../../../../interfaces/report_center";
import { LoadingType, THEME } from "../../../../../enums";
import "./master_tax_import_page.scss";
import { mtrImportSliceActions } from "../../../../../redux/report_center/payroll_tax/master_tax_reports/import_page/mtr_import_reducer";
import ApiError from "../../../../../components/api_error";
import { quarterOptions } from "../../../../../variables";

const MasterTaxReportsImportPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const importState = useAppSelector((state) => selectMTRState(state));
    const currentYear = new Date().getFullYear();
    const initialFormState: IMTExtract = {
        id: "",
        desc: "",
        report_name: "",
        quarter: "",
        year: null,
        process_id: "",
    }
    const [formState, setFormState] = useState<IMTExtract>(initialFormState);
    const [successMessage, setSuccessMessage] = useState<string | null>('');

    useEffect(() => {
        if (importState.postImport.loading === LoadingType.succeeded) {
            setSuccessMessage(importState.postImport.response);
            setFormState(initialFormState);
            setTimeout(() => setSuccessMessage(''), 5000);
            dispatch(mtrImportSliceActions.clearPostState());
        }
    }, [importState.postImport.loading])

    const handleFormChange = (id: string, val: any) => {
        setFormState({ ...formState, [id]: val })
    };

    const handleProcessFile = () => {
        if (formState)
            dispatch(postMasterTaxReportImport({ ...formState, year: formState.year ? parseInt(formState.year.toString()) : null }))
    }
    const _isFormValid = () => {
        const { year, quarter, process_id, report_name } = formState;

        if (year === null || report_name === "" ||
            report_name === null || quarter === "" || quarter === null ||
            process_id === "" || process_id === null) {
            return false;
        }
        return true;
    }
    const formValidators = (value: { text: string; field: string }) => {
        switch (value.field) {
            case "process_id":
            case "report_name":
            case "quarter": {
                if (value.text === "" || value.text === null) return t("validators.required");
                return null;
            }
            case "year": {
                if (value.text === "" || value.text === null) return t("validators.required");
                if (value.text.length < 4) return t("validators.year_length");
                return null;
            }
            default: {
                return null;
            }
        }
    }
    const handleClearError = () => {
        dispatch(mtrImportSliceActions.clearPostErrorState())
    }
    return (<div className="mtr-i-container">
        <div className="mtr-i-header">
        
        </div>
        <div className="mtr-i-content">
            <div className="mtr-i-row">
                <div className="ip-input">
                    <FormInput
                        value={formState.process_id}
                        placeholder={t('')}
                        type={"text"}
                        id={"process_id"}
                        label={t('process_id')}
                        required
                        onChange={(id, val) => handleFormChange(id, val)}
                        validations={formValidators}
                    />
                </div>
                <div className="ip-input">
                    <FormInput
                        value={formState.report_name}
                        placeholder={t('')}
                        type={"text"}
                        id={"report_name"}
                        label={t('report_name')}
                        onChange={(id, val) => handleFormChange(id, val)}
                        required
                        validations={formValidators}
                    />
                </div>
                <div className="ip-input">
                    <CustomFormSelect
                        name={t("quarter")}
                        list={(quarterOptions).map(doc => ({
                            value: doc.value,
                            label: doc.label
                        }))}
                        onChange={(val) => handleFormChange("quarter", val)}
                        value={formState?.quarter}
                        placeholder={t('Select')}
                        required
                        label={t('Quarter')}
                        validations={formValidators}
                    />
                </div>
            </div>
            <div className="mtr-i-row">
                <div className="ip-input desc">
                    <FormInput
                        placeholder={currentYear}
                        type={"text"}
                        id={"year"}
                        onChange={(id, val) => handleFormChange(id, val)}
                        value={formState.year}
                        label={t('year')}
                        maxLength={4}
                        onKeyDown={enforceFormat}
                        onKeyUp={enforceFormat}
                        required
                        validations={formValidators}
                    />
                </div>
                <div className="ip-input desc">
                    <FormInput
                        placeholder={t('enter_desc_file_optional')}
                        type={"text"}
                        id={"desc"}
                        onChange={(id, val) => handleFormChange(id, val)}
                        value={formState.desc}
                        label={t('description')}
                        multiline={true}
                        numberOfLines={10}

                    />
                </div>

            </div>
        </div>
        <div className="mtr-i-actions">
            <div className="mtr-i-span">
                <span className="success">{successMessage}</span>
                {importState.postImport.error &&
                    <ApiError message={importState.postImport.error.message} onClose={handleClearError} />}
            </div>
            <div className="mtr-ia-row">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('cancel')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => { setFormState(initialFormState) }}
                    />
                </div>
                <div className="btn-save">
                    <CustomButton
                        loading={importState.postImport.loading === LoadingType.pending}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('process_file')}
                        enable={_isFormValid()}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={handleProcessFile}
                    />
                </div>
            </div>
        </div>

    </div>);
}

export default MasterTaxReportsImportPage;