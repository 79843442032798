import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom"
import SearchBar from "../../../../components/search_bar";
import { CustomButton, currencyConversion, getPlusBtnIcon, getTableAccessIcon } from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { deleteWorkCompConfigList, getWorkCompConfigList, selectWorkCompConfigList, selectWorkCompConfigListState } from "../../../../redux/admin_center";
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from "../../../../components/table_empty/table_empty";
import { useEffect, useState } from "react";
import { WorkCompConfiguration } from "../../../../interfaces/work_comp_configuration";
import { Portal } from "react-portal";
import AddWorkCompPopUp from "../../popups/add_work_comp/add_work_comp";
import { DeleteIcon, EditIcon } from "../../../../icons";
import { clearWorkCompCreateState, clearWorkCompCreateStateError, clearWorkCompDeleteStateError, clearWorkCompUpdateState, clearWorkCompUpdateStateError } from "../../../../redux/admin_center/work_comp_configuration/work_comp_configuration_reducer";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";
import '../../payroll_tax_configuration/universal_directory_page/universal_directory_page.scss'
import { getDateString } from "../../../../variables";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
  { title: "work_comp_class_code", code: "work_comp_code" },
  { title: "state", code: "state" },
  { title: "base_rate", code: "base_rate" },
  { title: "description", code: "description" },
  { title: "start_date", code: "start_date" },
  { title: "end_date", code: "end_date" },
  { title: "", code: "" },
  { title: "", code: "" },
];

const UniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {
  const { history, match, location } = props;
  const { params } = match;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const WorkCompConfigListState = useAppSelector((state) => selectWorkCompConfigListState(state))
  const WorkCompConfigList = useAppSelector((state) => selectWorkCompConfigList(state))

  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState('');
  const [addWorkComp, setAddWorkComp] = useState<boolean>();
  const [editWorkComp, setEditWorkComp] = useState<{ visible: boolean, data: WorkCompConfiguration } | null>(null);

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [deleteWorkComp, setDeleteWorkComp] = useState<{ visible: boolean, workCompId: string | undefined, workCompName: string | undefined } | null>()

  useEffect(() => {
    getWorkCompTaxConfigDetails()
  }, []);

  useEffect(() => {
    if (WorkCompConfigListState?.create?.loading === LoadingType.succeeded || WorkCompConfigListState?.update?.loading === LoadingType.succeeded || WorkCompConfigListState?.delete?.loading === LoadingType.succeeded) {
      getWorkCompTaxConfigDetails()
    }
  }, [WorkCompConfigListState?.create?.loading, WorkCompConfigListState?.update?.loading, WorkCompConfigListState?.delete?.loading]);

  useEffect(() => {
    if (WorkCompConfigListState?.delete?.loading === LoadingType.succeeded) {
      if (snackBarMessage != "")
        dispatch(postSnackbarMessage(snackBarMessage ?? null));
    }
  }, [WorkCompConfigListState?.delete?.loading])

  const getWorkCompTaxConfigDetails = () => {
    dispatch(getWorkCompConfigList());
  }

  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  const handleEdit = (val: WorkCompConfiguration) => {
    setEditWorkComp({ visible: true, data: val });
  };
  const handleOnDelete = (id: string) => {
    if (id) {
      dispatch(deleteWorkCompConfigList(id))
    }
    setDeleteWorkComp(null)
    setSnackBarMessage("Work Comp Deleted Successfully!");
    dispatch(clearWorkCompDeleteStateError())
  };
  const handleDeleteWorkComp = (id: string, name: string) => {
    setDeleteWorkComp({ visible: true, workCompId: id, workCompName: name });
  };

  function isError() {
    return WorkCompConfigListState.create.error || WorkCompConfigListState.update.error
  }

  function isLoading() {
    return WorkCompConfigListState.create.loading || WorkCompConfigListState.update.loading
  }

  function handleWorkCompSuccess() {
    setAddWorkComp(false);
    setEditWorkComp(null);
    getWorkCompTaxConfigDetails()
    dispatch(clearWorkCompCreateState());
    dispatch(clearWorkCompUpdateState());
  }

  function handleOnClose() {
    setAddWorkComp(false);
    setEditWorkComp(null);
    handleClearError()
  }
  function handleOnDissmiss() {
    setAddWorkComp(false);
    setEditWorkComp(null);
    handleClearError()
  }

  //Handle clear error
  function handleClearError() {
    dispatch(clearWorkCompCreateStateError());
    dispatch(clearWorkCompUpdateStateError());
    dispatch(clearWorkCompDeleteStateError())
  }

  function getFilteredList(): WorkCompConfiguration[] {
    const list = [...WorkCompConfigList];
    let sortedList: WorkCompConfiguration[] | undefined;
    let resultList: WorkCompConfiguration[] | undefined;

    if (sortedField != null) {
      sortedList = [...list].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }


    return (sortedList ?? resultList ?? list).filter(doc => {
      const str = search.trim().toLowerCase();
      const workCompClassCode = doc.work_comp_code ? doc.work_comp_code.trim().toLowerCase().includes(str) : false;
      const state = doc.state ? doc.state.trim().toLowerCase().includes(str) : false;
      const baseRate = doc.base_rate ? doc.base_rate.toString().trim().toLowerCase().includes(str) : false;
      const description = doc.code_description ? doc.code_description.trim().toLowerCase().includes(str) : false;

      return workCompClassCode || baseRate || description || state
    });
  }

  function getWorkCompCodeTableList() {
    if (WorkCompConfigListState.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (WorkCompConfigListState.error != null && WorkCompConfigList.length === 0) {
      return (
        <TableErrorHandler
          error={WorkCompConfigListState.error}
          onRefresh={getWorkCompTaxConfigDetails}
        />
      );
    }
    if (WorkCompConfigList.length === 0) {
      return (
        <TableEmpty
          title={t("no_work_comp_code_matches_found")}
          onClick={() => getWorkCompTaxConfigDetails()}
        />
      );
    }
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_work_comp_code_found")} />;
    }


    return (
      <SortableTable
        headerList={tableHeader}
        sortedField={sortedField}
        onSortChange={handleSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={sortDirection}
      >
        {getFilteredList().map((doc, index) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                <span>
                  {doc.work_comp_code ?? ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                <span>{doc.state ? doc.state : "N/A"}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(2) }}>
                <span>{doc.base_rate
                  ? currencyConversion(doc.base_rate)
                  : "$0.00"}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                <span>{doc.code_description ? doc.code_description : "N/A"}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(4) }}>
                {getDateString(doc.start_date, "mm/dd/yyyy")}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                {getDateString(doc.end_date, "mm/dd/yyyy")}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                <span>
                  <button
                    className="icon-btn tdp-edit-icon"
                    onClick={() => handleEdit(doc)}
                  >
                    <EditIcon
                      width={"100%"}
                      height={"100%"}
                      style={{ color: "#fff" }}
                    />
                  </button>
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                <span>
                  <button
                    className="icon-btn tdp-edit-icon"
                    onClick={() => handleDeleteWorkComp(doc.id, doc.work_comp_code)}
                  >
                    <DeleteIcon
                      width={"100%"}
                      height={"100%"}
                      style={{ color: "#fff" }}
                    />
                  </button>
                </span>
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 1;
    if (value === 1) return 1;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 0.1;
    if (value === 7) return 0.1;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10) return 1;
    return 1;
  }

  return (
    <div className="ac-prtc-ud-container">
      <div className="ac-prtc-ud-table-header">
        <div className="total-count">
          <span>
            {`${t("total_count")}: `}
            <span className="total-count-number">{getFilteredList().length}</span>
          </span>
        </div>
        <div className="ac-prtc-ud-actions">
          <div className="ac-prtc-ud-table-search-bar">
            <SearchBar
              value={search}
              onChange={setSearch}
              onSearch={() => { }}
            />
          </div>
          <div className="ac-prtc-ud-add-user-btn">
            <CustomButton
              leftIcon={getPlusBtnIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("add_wc_code")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setAddWorkComp(true)}
            />
          </div>
        </div>
      </div>
      <div className="ac-prtc-ud-table">{getWorkCompCodeTableList()}</div>
      {addWorkComp && (
        <Portal>
          <AddWorkCompPopUp
            visible={addWorkComp}
            onClose={handleOnClose}
            onDismiss={handleOnDissmiss}
            error={isError()}
            loading={isLoading()}
            onSuccessClose={handleWorkCompSuccess}
            onClearError={handleClearError}
            workCompListState={WorkCompConfigListState}
          />
        </Portal>
      )}
      {editWorkComp && (
        <Portal>
          <AddWorkCompPopUp
            visible={editWorkComp.visible}
            onClose={handleOnClose}
            onDismiss={handleOnDissmiss}
            workCompState={editWorkComp?.data}
            error={isError()}
            loading={isLoading()}
            onSuccessClose={handleWorkCompSuccess}
            onClearError={handleClearError}
            workCompListState={WorkCompConfigListState}
          />
        </Portal>
      )}
      {deleteWorkComp?.visible && (
        <DeleteConfirmationDialog
          message={t("delete_work_comp_message", {
            workComp: deleteWorkComp?.workCompName,
          })}
          onCancel={() => setDeleteWorkComp(null)}
          onConfirm={() => handleOnDelete(deleteWorkComp?.workCompId!)}
          deleting={WorkCompConfigListState?.delete?.loading === LoadingType.pending}
          deleteType={t("workComp")}
          state={WorkCompConfigListState?.delete}
          onClearError={handleClearError}
        />
      )}
    </div>
  );
}

export default UniversalDirectoryPage
