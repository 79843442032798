import { useTranslation } from "react-i18next";
import {
    getDownloadFileURLThunk,
    getMTRdirectory,
    selectFileDownloadState,
    selectMTRState,
    useAppDispatch,
    useAppSelector,
} from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { LoadingType, MTExtractFileStatus, THEME } from "../../../../../enums";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { IMTExtract } from "../../../../../interfaces";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import SearchBar from "../../../../../components/search_bar";
import { CustomButton, SpinnerScreen } from "../../../../../utils";
import { quarterOptions } from "../../../../../variables";
import "./master_tax_import_page.scss";
import { mtrImportSliceActions } from "../../../../../redux/report_center/payroll_tax/master_tax_reports/import_page/mtr_import_reducer";
import { DownloadIcon } from "../../../../../icons";
import { fileDownloadSliceActions } from "../../../../../redux/files/file_download/file_download_reducer";

const MasterTaxReportDirectoryPage = () => {
    const tableHeader = [
        { title: "process_name", code: "process_name" },
        { title: "report_name", code: "report_name" },
        { title: "year", code: "year" },
        { title: "quarter", code: "quarter" },
        { title: "status", code: "status" },
        { title: "description", code: "desc" },
        { title: "", code: "", sort: false },
    ];

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const reportsDirectoryListState = useAppSelector((state) =>
        selectMTRState(state)
    );
    const fileDownloadState = useAppSelector((state) => selectFileDownloadState(state));

    const [reportsDirectoryList, setReportsDirecoryList] = useState<IMTExtract[]>([]);

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 80 }, (_, i) => {
        const year = 2020 + i;
        return { label: year.toString(), value: year.toString() };
    });

    const [queryParam, setQueryParam] = useState<{
        year: number;
        quarter: string;
    }>({ year: currentYear, quarter: "" });

    useEffect(() => {
        dispatch(mtrImportSliceActions.clearReportsState());
        dispatch(getMTRdirectory(queryParam));
    }, []);


    useEffect(() => {
        if (fileDownloadState.loading === LoadingType.succeeded && fileDownloadState.response) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', fileDownloadState.response);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(fileDownloadSliceActions.clearState());
        }
    }, [fileDownloadState.loading]);

    useEffect(() => {
        if (
            reportsDirectoryListState.reports.loading === LoadingType.succeeded &&
            reportsDirectoryListState.reports.response
        ) {
            setReportsDirecoryList(reportsDirectoryListState.reports.response);
        }
    }, [
        reportsDirectoryListState.reports.loading,
        reportsDirectoryListState.reports.response,
    ]);
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [dwnldBtnIndex, setDwnldBtnIndex] = useState<number | null>(null);

    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    }
    const getFilteredList = () => {
        let list: IMTExtract[] = reportsDirectoryList;
        if (sortedField != null) {
            list = [...reportsDirectoryList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return list.filter((doc) => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(
                (val) => val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    };
    const getFlexNumber = (value: number) => {
        if (value === 0) return 2;
        if (value === 1) return 2;
        if (value === 5) return 2.5;
        if (value === 6) return 1.5;


        return 1;
    };
    const handleFieldChange = (id, val) => {
        setQueryParam({ ...queryParam, [id]: val });
        dispatch(getMTRdirectory({ ...queryParam, [id]: val }));
    };
    const handleDownload = (filePath, index) => {
        setDwnldBtnIndex(index);
        dispatch(getDownloadFileURLThunk(filePath));
    }
    return (
        <div className="mtr-rd-container">
            <div className="mtr-rd-header">
                <div className="mtr-rd-hl">
                    <CustomFormSelect
                        list={years.map((doc) => ({
                            label: doc.label,
                            value: doc.value,
                        }))}
                        name={"year"}
                        value={queryParam.year.toString()}
                        label={t("year")}
                        placeholder={"Select"}
                        onChange={(val) => handleFieldChange("year", val)}
                    />
                    <CustomFormSelect
                        name={t("quarter")}
                        list={(quarterOptions).map(doc => ({
                            value: doc.value,
                            label: doc.label
                        }))}
                        onChange={(val) => handleFieldChange("quarter", val)}
                        value={queryParam.quarter}
                        placeholder={t('Select')}
                        label={t('Quarter')}
                    />
                </div>
                <div className="mtr-rd-hr">
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <span><b>{getFilteredList().length}</b></span>
                        </span>
                    </div>
                    <SearchBar
                        value={search}
                        onChange={setSearch}
                        onSearch={() => { }}
                        placeholder="Search..."
                    />
                </div>
            </div>
            <div className="mtr-rd-content">
                <div className="mtr-rd-table">
                    {reportsDirectoryListState &&
                        reportsDirectoryListState.reports.loading === LoadingType.pending ? (
                        <>
                            <SpinnerScreen></SpinnerScreen>
                        </>
                    ) : (
                        <SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList().length > 0 ? (
                                <>
                                    {getFilteredList().map((doc, index) => {
                                        return (
                                            <tr key={index + "id"}>
                                                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                    <span>{doc.process_name}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                                    <span>{doc.report_name}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                    <span>{doc.year}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                    <span>
                                                        {doc.quarter}
                                                    </span>
                                                </TableData>
                                                <TableData
                                                    customStyle={{ flex: getFlexNumber(4) }}
                                                >
                                                    <span style={{ color: doc.status === MTExtractFileStatus.COMPLETED ? THEME.statusActiveColor : (doc.status === MTExtractFileStatus.FAILED ? THEME.statusInActiveColor : THEME.statusWarningColor )}}>{doc.status}</span>
                                                </TableData>
                                                <TableData
                                                    customStyle={{ flex: getFlexNumber(5) }}
                                                >
                                                    <span>{doc.desc}</span>
                                                </TableData>
                                                <TableData
                                                    customStyle={{ flex: getFlexNumber(6) }}>
                                                    <CustomButton
                                                        textStyle={{ textTransform: 'capitalize' }}
                                                        enable={true}
                                                        leftIcon={<div><DownloadIcon style={{ height: "1vw", width: "1vw" }} /></div>}
                                                        name={t("download")}
                                                        backgroundColor={THEME.buttonColor16}
                                                        onClick={() => (handleDownload(doc.file_path, index))}
                                                        loading={index === dwnldBtnIndex && fileDownloadState.loading === LoadingType.pending}
                                                    />
                                                </TableData>
                                            </tr>
                                        );
                                    })}
                                </>
                            ) : (
                                <tr>
                                    <TableData
                                        customStyle={{ flex: getFlexNumber(20) }}
                                        align="center"
                                    >
                                        <div
                                            className="na_div"
                                            style={{
                                                width: "100%",
                                                fontSize: "1vw",
                                                textAlign: "center",
                                            }}
                                        >
                                            {t("no_reports_available")}
                                        </div>
                                    </TableData>
                                </tr>
                            )}
                        </SortableTable>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MasterTaxReportDirectoryPage;
