import { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../../../../components/search_bar";
import { LoadingType, THEME } from "../../../../../../enums";
import { CustomButton, currencyConversion, formatPercentage, getPlusBtnIcon } from "../../../../../../utils";
import './rates_job_title.scss';
import { AppError, ICompBOInvoiceDocsRatesJobTitle, ICompanyBackOfficeInvoiceDocs } from "../../../../../../interfaces";
import { useAppDispatch } from "../../../../../../redux/store";
import SortableTable, { TableData } from "../../../../../../components/sortable_table/sortable_table";
import { Portal } from "react-portal";
import AddNewRateByJobPopup from "../../../../popup/add_rate_by_job/add_rate_by_job_popup";
import { DeleteIcon, EditIcon } from "../../../../../../icons";
import BODeleteRecordPopup from "../../../../popup/delete_record_popup/delete_record_popup";
import { companyBOInvoiceDocsSliceActions } from "../../../../../../redux/companies/details/back_office_invoices_and_docs/back_office_invoices_and_docs_reducer";

const RateJobsTableHeader = [
    { title: 'job_title', code: 'job_title' },
    { title: 'Shift', code: 'shift' },
    { title: 'rjt_mark_up', code: 'mark_up_date' },
    { title: 'rjt_payrate', code: 'pay_rate' },
    { title: '', code: '' },

];
interface Props {
    formState: ICompanyBackOfficeInvoiceDocs;
    currentLoginUserName: string;
    handleAddRateJob: (doc: ICompBOInvoiceDocsRatesJobTitle, index?: number, removeItem?: boolean) => void;
    codeRateOptionsUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleClearError: () => void;
    companyId: string;
    handleUpdateClearState: () => void;
    getInvoiceDocsData: () => void;
}
const CompanyBORatesJobTitle: React.FC<Props> = (props) => {
    const { formState, currentLoginUserName, handleAddRateJob, codeRateOptionsUpdateState, handleClearError, companyId,
        handleUpdateClearState, getInvoiceDocsData } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [addNewRateJob, setAddNewRateJob] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [rateJobsSorted, setRateJobsSorted] = useState<string | null>(null);
    const [editIndex, setEditIndex] = useState<number | undefined>();
    const [delRecord, setDelRecord] = useState<boolean>(false);

    const initialForm = {
        job_title: "",
        shift: "",
        mark_up_date: 0,
        pay_rate: 0
    }
    const [rateJobFormState, setRateJobFormState] = useState<ICompBOInvoiceDocsRatesJobTitle>(initialForm);

    function handleFormFieldChange(fieldId: string, value: any, parent: string) {
        if (fieldId === "mark_up_date") {
            if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
                setRateJobFormState({
                    ...rateJobFormState,
                    [fieldId]: value,
                });
            }
        }
        else {
            setRateJobFormState({
                ...rateJobFormState,
                [fieldId]: value,
            });
        }

    };
    function handlerateJobsSortFieldChange(value: string) {
        if (rateJobsSorted && rateJobsSorted === value) {
            setRateJobsSorted(null);
        } else {
            setRateJobsSorted(value);
        }
    }
    function getrateJobsFilteredList(): ICompBOInvoiceDocsRatesJobTitle[] {
        let sortedList: ICompBOInvoiceDocsRatesJobTitle[] | undefined;
        if (rateJobsSorted != null) {
            sortedList = [...(formState.rates_by_job_title ?? [])].sort((a, b) => {
                if (a[rateJobsSorted] < b[rateJobsSorted]) {
                    return -1;
                }
                if (a[rateJobsSorted] > b[rateJobsSorted]) {
                    return 1;
                }
                return 0;
            });
        }
        return (sortedList ?? (formState.rates_by_job_title ?? [])).filter(doc => {
            const str = search.trim().toLowerCase();
            const job_title = doc.job_title ? doc.job_title.trim().toLowerCase().includes(str) : false;
            const shift = doc.shift ? doc.shift.trim().toLowerCase().includes(str) : false;
            const markup = doc.mark_up_date ? doc.mark_up_date.toString().trim().toLowerCase().includes(str) : false;
            const payRate = doc.pay_rate ? doc.pay_rate.toString().trim().toLowerCase().includes(str) : false;

            return job_title || shift || markup || payRate;

        });
    }
    const handleSuccessClose = () => {
        setEditIndex(undefined);
        setAddNewRateJob(false);
        setRateJobFormState(initialForm);
        dispatch(companyBOInvoiceDocsSliceActions.initializeState(companyId));
    }
    const handleSave = () => {
        if (JSON.stringify(rateJobFormState) !== JSON.stringify(initialForm)) {
            handleAddRateJob(rateJobFormState, editIndex);
        }
        handleSuccessClose();
    };
    const handleEdit = (val: ICompBOInvoiceDocsRatesJobTitle, index: number) => {
        setAddNewRateJob(true);
        setRateJobFormState(val);
        setEditIndex(index);
    };
    const handleDelete = (val: ICompBOInvoiceDocsRatesJobTitle, index: number) => {
        setDelRecord(true);
        setRateJobFormState(val);
        setEditIndex(index);
    };
    const handleOnSuccessRemove = () => {
        handleAddRateJob(rateJobFormState, editIndex, true);
        handleSuccessClose();
        setDelRecord(false);
    }
    return (
        <div className="rates-jt-container">
            <div className="code-ro-header">
                <div className="section-header">{t("rates_by_job_title")}</div>
                <div className="code-ro-header-actions">
                    <div className="">
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                            placeholder={t('Search')}
                        />
                    </div>
                    <CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        textStyle={{ textTransform: "none" }}
                        name={t("add_new_rate_by_job")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => setAddNewRateJob(true)}
                    />
                </div>
            </div>
            <div className="code-ro-content">
                <div className="code-ro-content-table">
                    <SortableTable
                        headerList={RateJobsTableHeader}
                        sortedField={rateJobsSorted}
                        onSortChange={handlerateJobsSortFieldChange}
                        flexNumber={getFlexNumber}
                    >
                        {getrateJobsFilteredList().map((doc, index) => {
                            return (
                                <tr key={index} onClick={() => { }}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}>{doc.job_title}</TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}>{doc.shift}</TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{formatPercentage(doc.mark_up_date)}</span></TableData>

                                    <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc.pay_rate
                                        ? currencyConversion(doc.pay_rate)
                                        : "$0.00"}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <button className="edit-icon" >
                                            <EditIcon width={"100%"} height={"100%"} onClick={() => handleEdit(doc, index)} />
                                        </button>
                                        <button className="delete-icon" >
                                            <DeleteIcon width={"100%"} height={"100%"} onClick={() => handleDelete(doc, index)} />
                                        </button>
                                    </TableData>
                                </tr>
                            );
                        })}
                    </SortableTable>
                </div>
            </div>
            {addNewRateJob &&
                <Portal>
                    <AddNewRateByJobPopup
                        visible={addNewRateJob}
                        onClose={handleSuccessClose}
                        onSuccessClose={handleSuccessClose}
                        currentLoginUserName={currentLoginUserName}
                        handleFormFieldChange={handleFormFieldChange}
                        rateJobFormState={rateJobFormState}
                        handleSave={handleSave}
                        codeRateOptionsUpdateState={codeRateOptionsUpdateState}
                        handleClearError={handleClearError}
                        index={editIndex}
                    />
                </Portal>
            }
            {delRecord &&
                <Portal>
                    <BODeleteRecordPopup
                        clearError={handleClearError}
                        error={codeRateOptionsUpdateState.error}
                        onRemove={handleOnSuccessRemove}
                        onCancel={() => setDelRecord(false)}
                        saving={false}
                        title={t("delete_bo_record_message", {
                            name: rateJobFormState.job_title,
                        })}
                        visible={delRecord}
                    />
                </Portal>
            }
        </div>
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 0.5;

        return 1;
    }
}

export default CompanyBORatesJobTitle;