import { CheckCircleOutline, Timer } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import { DateInput } from "../../../../components/date_input/date_input";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { TimeInput } from "../../../../components/time_input/time_input";
import { LoadingType, THEME } from "../../../../enums";
import {
  FacebookIcon,
  IndeedIcon,
  InstagramIcon,
  LinkedInIcon,
  PaperPlaneIcon,
  TwitterIcon,
  ZipRecruiterIcon,
} from "../../../../icons";
import { MarketingCampaign, UpdateMarketingCampaign } from "../../../../interfaces/marketing_campaign";
import { getMarketingCampaigns } from "../../../../redux/sales_marketing/marketing_activity/marketing_campaign_thunk";
import { manageMarketingCampaignActions } from "../../../../redux/sales_marketing/plan_marketing_campaign/manage_marketing_campaign_reducer";
import {
  selectManageMarketingCampaignCreateState,
  selectManageMarketingCampaignUpdateState,
} from "../../../../redux/sales_marketing/plan_marketing_campaign/manage_marketing_campaign_selector";
import {
  createMarketingCampaign,
  updateMarketingCampaign,
} from "../../../../redux/sales_marketing/plan_marketing_campaign/manage_marketing_campaign_thunk";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { convertNumberToMoney, CustomButton, enforceUSFormat, formatToMoney, FormInput, RegxPattern } from "../../../../utils";
import { convertTimestampToStringTime, dateTimeToTimestamp, lowBtnStyle, timestampToStringDate } from "../../../../variables";
import "./plan_marketing_campaign.scss";
import TimePickerComponent from "../../../../components/time_picker/time_picker";

interface Props {
  showModal: boolean;
  setEditPopup: boolean;
  marketingCampaignInfo: MarketingCampaign;
  successTitle: string;
  currentLoginUserName?: string;
  onGotoAgencyDashboard: () => void;
  onCreateNew: () => void;
  onGotoCreated: (id: string, name: string) => void;
  onClose: () => void;
  onDissmiss?: () => void,
  onSuccessClose: () => void,
}

const getInitialState = (value?: MarketingCampaign | null) => {
  if (value) {
    return {
      ...value,
      budget: convertNumberToMoney(value.budget, 'string') as string,
      gross_profit_potential: convertNumberToMoney(value.gross_profit_potential, 'string') as string,
    }
  }
  return new UpdateMarketingCampaign();
}

const PlanMarketingCampaign: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { showModal, marketingCampaignInfo, onSuccessClose, setEditPopup } = props;
  const [marketingCampaign, setMarketingCampaign] = useState<UpdateMarketingCampaign>(
    getInitialState(props.marketingCampaignInfo)
  );
  const actionRef = useRef<ActionDialogHolderType>(null);
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);
  const [error, setError] = useState('');
  const socialMediaPlatforms = [
    "linked_in",
    "indeed",
    "zip_recruiter",
    "facebook",
    "twitter",
    "instagram",
  ];
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const createState = useAppSelector((state) =>
    selectManageMarketingCampaignCreateState(state)
  );
  const updateState = useAppSelector((state) =>
    selectManageMarketingCampaignUpdateState(state)
  );

  useEffect(() => {
    if (createState.loading == LoadingType.succeeded) {
      //dispatch(postSnackbarMessage(createState.response ?? null));
      dispatch(getMarketingCampaigns());
      // dispatch(
      //   manageMarketingCampaignActions.clearCreateMarketingCampaignState()
      // );
      // callbackHandler();
      //  setMarketingCampaign(new MarketingCampaign());
    }
    if (updateState.loading == LoadingType.succeeded) {
      // dispatch(postSnackbarMessage(updateState.response ?? null));
      dispatch(getMarketingCampaigns());
      // dispatch(
      //   manageMarketingCampaignActions.clearUpdateMarketingCampaignState()
      // );
      // callbackHandler();
    }
  }, [createState.loading, updateState.loading]);

  const updateMarketingCampaignProp = (key: string, value: any) => {
    const newMarketingCampaign = { ...marketingCampaign };
    newMarketingCampaign[key] = value;
    setMarketingCampaign(newMarketingCampaign);
  };
  useEffect(() => {
    if (updateState && updateState.loading === LoadingType.succeeded) {
      onSuccessClose()
    }
  }, [updateState, updateState.loading])
  const handleSave = () => {
    if (!isSocialMediaSelected(marketingCampaign.social_media_platforms_to_publish)) {
      setError(t('validators.required'));
    }
    setCheckValidationOnSubmit(true);
    if (!_isFormValid()) {
      return;
    }
    const startDateTimestampValue = timestampToStringDate(marketingCampaign?.start_date)
    const startTimeStringValue = marketingCampaign?.start_time
    const endDateTimestampValue = timestampToStringDate(marketingCampaign?.end_date)
    const endTimeStringValue = marketingCampaign?.end_time
    const startDateAndTimeTimeStampValue = dateTimeToTimestamp(startDateTimestampValue, startTimeStringValue)! / 1000
    const endDateAndTimeTimeStampValue = dateTimeToTimestamp(endDateTimestampValue, endTimeStringValue)! / 1000
    let finalData: any = {
      ...marketingCampaign,
      budget: convertNumberToMoney(marketingCampaign.budget, 'number') as number,
      gross_profit_potential: convertNumberToMoney(marketingCampaign.gross_profit_potential, 'number') as number,
      start_date_time: startDateAndTimeTimeStampValue,
      end_date_time: endDateAndTimeTimeStampValue,
    };
    if (finalData.id) {
      dispatch(updateMarketingCampaign(finalData));
    }
    else {
      dispatch(createMarketingCampaign(finalData));
    }
    setCheckValidationOnSubmit(false);
  };

  function updateForm(value: MarketingCampaign) {
    setMarketingCampaign({
      ...value,
      budget: convertNumberToMoney(value.budget, 'string') as string,
      gross_profit_potential: convertNumberToMoney(value.gross_profit_potential, 'string') as string,
    });
  }

  const handleClearTime = (id: string) => {
    if (id === "start_time") {
      setMarketingCampaign({ ...marketingCampaign, start_time: "" })
    }
    if (id === "end_time") {
      setMarketingCampaign({ ...marketingCampaign, end_time: "" })
    }
  }

  const setSocialMediaPlatforms = (val) => {
    let smp: any = [];
    if (marketingCampaign.social_media_platforms_to_publish) {
      smp = [...marketingCampaign.social_media_platforms_to_publish];
    }
    if (isSocialMediaSelected(val)) {
      smp = smp.filter((i) => i !== t(val));
    } else {
      smp.push(t(val));
    }
    setMarketingCampaign({
      ...marketingCampaign,
      social_media_platforms_to_publish: smp,
    });
  };

  const getSocialMediaPlatform = (item, border) => {
    const borderColor = !isSocialMediaSelected(marketingCampaign.social_media_platforms_to_publish) ? border : "none";
    return (
      <div
        className={`contact-icon small ${isSocialMediaSelected(item) ? "selected" : ""
          }`}
        onClick={() => setSocialMediaPlatforms(t(item))}
        style={{ border: borderColor }}
      >
        {isSocialMediaSelected(item) && getCheckCircle(true)}
        {getSocialMediaicon(item)}
      </div>
    );
  };

  const isSocialMediaSelected = (sm) => {
    return (
      marketingCampaign.social_media_platforms_to_publish &&
      marketingCampaign.social_media_platforms_to_publish.findIndex(
        (item) => item === t(sm)
      ) !== -1
    );
  };

  const getCheckCircle = (small = false) => (
    <div className={`check-circle-outline ${small ? "small" : ""}`}>
      <CheckCircleOutline htmlColor="#00be4b" />
    </div>
  );

  const _isFormValid = () =>
    !!marketingCampaign.marketing_campaign_name &&
    !!marketingCampaign.start_date &&
    !!marketingCampaign.start_time &&
    !!marketingCampaign.end_date &&
    !!marketingCampaign.end_time &&
    !!isSocialMediaSelected(marketingCampaign.social_media_platforms_to_publish)


  const getSocialMediaicon = (socialMedia) => {
    switch (socialMedia) {
      case "facebook":
        return <FacebookIcon height={"30px"} width={"30px"} />;
      case "linked_in":
        return <LinkedInIcon height={"30px"} width={"30px"} />;
      case "zip_recruiter":
        return <ZipRecruiterIcon height="16px" width="60px" />;
      case "twitter":
        return <TwitterIcon height={"30px"} width={"30px"} />;
      case "indeed":
        return <IndeedIcon height="16px" width="65px" />;
      case "instagram":
        return <InstagramIcon height="30px" width="30px" />;
    }
  };

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={showModal}
      wrapperWidth={"50%"}
      onClose={props.onClose}
      onDissmiss={props.onClose}
    >
      <DialogWrapper onClose={() => closePopup(props.onClose)}>
        {
          getForm()
        }
      </DialogWrapper>
    </ActionDialogHolder>
  );

  function getForm() {
    if (createState.loading === LoadingType.succeeded) {
      return (
        <div className="tlnt-f-container">
          <div className="tlnt-f-header">
            <FormDialogTilteHeader
              title={props.successTitle}
              titleIcon={<PaperPlaneIcon width={"100%"} height={"100%"} style={{ color: "#79649E" }}
              />}
            />
          </div>
          <div className="tlnt-f-content">
            <div className="saved-doc-holder">
              <DocSavedText>
                <span className="aknw-name">
                  {t("well_done_marketing", {
                    name: props.currentLoginUserName,
                  })}
                </span>
              </DocSavedText>
            </div>
          </div>
          <div className="tlnt-f-actions">
            <div className="btn-cancel">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize", ...lowBtnStyle.text }}
                name={t("back_to_my_agency_dashboard")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={handleGotoAgencyDashboard}
                customStyle={lowBtnStyle.btn}
              />
            </div>

            <div className="btn-save">
              <CustomButton
                loading={false}
                textStyle={{
                  textTransform: "capitalize",
                }}
                name={t("plan_another_marketing_campaign")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => {
                  updateForm(marketingCampaignInfo);
                  props.onCreateNew();
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="plan-campaign">
        <FormDialogTilteHeader
          title={marketingCampaign && setEditPopup ? t("let's_update_a_marketing_campaign") : t("let's_plan_a_marketing_campaign")}
          titleIcon={
            <PaperPlaneIcon
              width={"100%"}
              height={"100%"}
              style={{ color: "#79649E" }}
            />
          }
        />
        <div className="form-container">
          <div style={{ marginRight: "5em" }}>
            <div className="call-goal">
              <FormInput
                id={"marketing_campaign_name"}
                onChange={(field: any, value: any) => {
                  if (value !== null) {
                    updateMarketingCampaignProp(
                      "marketing_campaign_name",
                      value
                    );
                  }
                }}
                required={true}
                placeholder={""}
                type={"text"}
                value={marketingCampaign.marketing_campaign_name}
                validations={PlanMCFormValidators}
                checkValidationOnSubmit={checkValidationOnSubmit}
                label={"What is the name of this Marketing Campaign?"}
                labelStyles={{ fontSize: "0.850vw", marginTop: "25px", fontWeight: 700, textTransform: "none" }}
              />
            </div>
            <div className="call-goal">
              <FormInput
                id={"marketing_campaign_goal"}
                onChange={(field: any, value: any) => {
                  if (value !== null) {
                    updateMarketingCampaignProp(field, value);
                  }
                }}
                required={false}
                placeholder={""}
                type={"text"}
                multiline={true}
                value={marketingCampaign.marketing_campaign_goal}
                label={"What is the goal of this Marketing Campaign?"}
                labelStyles={{ fontSize: "0.850vw", marginTop: "25px", fontWeight: 700, textTransform: "none" }}
                numberOfLines={3}
              />
            </div>
            <div className="call-goal">
              <FormInput
                id={"target_audience"}
                onChange={(field: any, value: any) => {
                  if (value !== null) {
                    updateMarketingCampaignProp(field, value);
                  }
                }}
                required={false}
                placeholder={""}
                type={"text"}
                value={marketingCampaign.target_audience}
                label={
                  "Who is the target audience of this Marketing Campaign?"
                }
                labelStyles={{ fontSize: "0.850vw", marginTop: "25px", fontWeight: 700, textTransform: "none" }}
              />
            </div>
            <div className="call-goal">
              <FormInput
                id={"budget"}
                onChange={(id, val) =>
                  updateMarketingCampaignProp(id, enforceUSFormat(val))
                }
                type={"text"}
                value={convertNumberToMoney(marketingCampaign?.budget, "string") as string}
                label={t("What is the budget for this Marketing Campaign?")}
                placeholder=""
                prefix={<span>$</span>}
                step="0.01"
                min={"0.00"}
                labelStyles={{ fontSize: "0.850vw", marginTop: "25px", fontWeight: 700, textTransform: "none" }}
                onBlur={formatToMoney}
                onKeyDown={formatToMoney}
                onKeyUp={formatToMoney}
              />
            </div>
            <div className="call-goal">
              <FormInput
                id={"gross_profit_potential"}
                onChange={(id, val) =>
                  updateMarketingCampaignProp(id, enforceUSFormat(val))
                }
                type={"text"}
                value={convertNumberToMoney(marketingCampaign?.gross_profit_potential, "string") as string}
                label={t(
                  "What is the Gross Profit potential for this Campaign?"
                )}
                placeholder=""
                prefix={<span>$</span>}
                step="0.01"
                min={"0.00"}
                labelStyles={{ fontSize: "0.850vw", marginTop: "25px", fontWeight: 700, textTransform: "none" }}
                onBlur={formatToMoney}
                onKeyDown={formatToMoney}
                onKeyUp={formatToMoney}
              />
            </div>
          </div>
          <div>
            <div className="label">
              Please select the date and time you would like this campaign to
              go live:*
            </div>
            <div className="schedule-container">
              <div>
                <div className="sub-label">Date</div>
                <DateInput
                  id={"start_date"}
                  onChange={updateMarketingCampaignProp}
                  placeholder={""}
                  value={marketingCampaign.start_date}
                  validations={PlanMCFormValidators}
                  checkValidationOnSubmit={checkValidationOnSubmit}
                />
              </div>
              <div>
                <div className="sub-label">Time</div>
                {/* <TimeInput
                  id={"start_time"}
                  onChange={(id, val) => {
                    updateMarketingCampaignProp(id, val);
                  }}
                  placeholder={""}
                  value={marketingCampaign.start_date_time}
                  isDateTime
                /> */}
                <TimePickerComponent
                  id={"start_time"}
                  onChange={(id, val) => {
                    updateMarketingCampaignProp(id, val);
                  }}
                  placeholder={''}
                  value={marketingCampaign.start_time}
                  onClearTime={() => handleClearTime("start_time")}
                  icon={<Timer />}
                  validations={PlanMCFormValidators}
                  checkValidationOnSubmit={checkValidationOnSubmit}
                />
              </div>
            </div>
            <div className="label">
              Please select the date and time you would like this campaign to
              end:*
            </div>
            <div className="schedule-container">
              <div>
                <div className="sub-label">Date</div>
                <DateInput
                  id={"end_date"}
                  onChange={updateMarketingCampaignProp}
                  placeholder={""}
                  value={marketingCampaign.end_date}
                  validations={PlanMCFormValidators}
                  checkValidationOnSubmit={checkValidationOnSubmit}
                  required
                />
              </div>
              <div>
                <div className="sub-label">Time</div>
                {/* <TimeInput
                  id={"end_time"}
                  onChange={(id, val) => {
                    updateMarketingCampaignProp(id, val);
                  }}
                  placeholder={""}
                  value={marketingCampaign.end_date_time}
                  required
                /> */}
                <TimePickerComponent
                  id={"end_time"}
                  onChange={(id, val) => {
                    updateMarketingCampaignProp(id, val);
                  }}
                  placeholder={''}
                  value={marketingCampaign.end_time}
                  onClearTime={() => handleClearTime("end_time")}
                  icon={<Timer />}
                  validations={PlanMCFormValidators}
                  checkValidationOnSubmit={checkValidationOnSubmit}
                />
              </div>
            </div>
            <div>
              <div className="label">
                To which Social Media Platforms would you like to publish this
                campaign?*
              </div>
              <div className="contact-methods">
                <div>
                  <div className="flex-row" style={{ marginTop: "15px" }}>
                    {socialMediaPlatforms.map((item) =>
                      getSocialMediaPlatform(item, error && !marketingCampaign?.social_media_platforms_to_publish?.length && "1px solid red")
                    )}
                  </div>
                  {error && !marketingCampaign?.social_media_platforms_to_publish?.length && <span className="error-message">{error}</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="btn-cancel">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("cancel")}
              enable
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => {
                setMarketingCampaign(new UpdateMarketingCampaign());
                props.onClose();
              }}
            />
          </div>
          <div className="btn-save">
            <CustomButton
              loading={
                createState.loading === LoadingType.pending ||
                updateState.loading === LoadingType.pending
              }
              textStyle={{
                textTransform: "capitalize",
              }}
              name={marketingCampaign && setEditPopup ? t("update_marketing_campaign") : t("plan_marketing_campaign")}
              // enable={
              //   !!marketingCampaign.marketing_campaign_name &&
              //   // !!marketingCampaign.start_date_time &&
              //   // !!marketingCampaign.end_date_time &&
              //   !!marketingCampaign.start_date &&
              //   !!marketingCampaign.start_time &&
              //   !!marketingCampaign.end_date &&
              //   !!marketingCampaign.end_time &&
              //   // !!marketingCampaign.social_media_platforms_to_publish && 
              //   !!isSocialMediaSelected(marketingCampaign.social_media_platforms_to_publish)
              // }
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    )
  }
  function handleGotoAgencyDashboard() {
    closePopup(props.onGotoAgencyDashboard);
  }

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }

  function PlanMCFormValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "marketing_campaign_name":
      case "start_date":
      case "start_time":
      case "end_date":
      case "end_time":
      case "contact": {
        if (!value.text) return t("validators.required");
        return null;
      }
      case "website":

      case "linkedin_url":
      case "facebook_url":
      case "twitter_url":
      case "pinterest_url": {
        if (!value.text) return null;
        if (RegxPattern.url.test(value.text) === false)
          return t("validators.enterValidUrl");
        return null;
      }
      default:
        return null;
    }
  }
};


export default PlanMarketingCampaign;
